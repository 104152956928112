@import "styles/config";
@import "client";

@import "styles/helpers/drawer";

.scheduleDate {
  @extend %container;
  &-container {
    flex-grow: 1;
    padding: 1rem;
    overflow: auto;
  }
  &-fields {
    & div:last-child {
      margin-bottom: 0.25rem;
    }
  }
  &-label {
    font-size: 0.875rem;
    text-align: right;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }
  }
  &-button {
    padding: 1rem;
  }
  &-footer {
    padding: 1rem;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    &--button {
      padding: 1rem;
      height: 100%;
    }
  }

  @extend %scheduleDate !optional;
}
