.iframe {
  margin: 0;
  padding: 0;
  border: 0;
}

.hr {
  height: 1px;
  border: 0;
  background-color: #ccc;
  margin-top: 2px;
}

/* Define how SqPaymentForm iframes should look */
.sq-input {
  border: 1px solid #e0e2e3;
  border-radius: 4px;
  outline-offset: -2px;
  display: inline-block;
  -webkit-transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out;
  -moz-transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out;
  -ms-transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out;
}

/* Define how SqPaymentForm iframes should look when they have focus */
.sq-input--focus {
  border: 1px solid #4a90e2;
  background-color: rgba(74, 144, 226, 0.02);
}

/* Define how SqPaymentForm iframes should look when they contain invalid values */
.sq-input--error {
  border: 1px solid #e02f2f;
  background-color: rgba(244, 47, 47, 0.02);
}

.sq-card-number {
}

.error {
  width: 100%;
  margin-top: 1rem;
  font-size: 0.875rem;
  color: red;
  font-weight: 500;
  text-align: center;
  opacity: 0.8;
}
