@import "styles/config";
@import "client";

.redeem-form {
  &-navigation {
    padding: 15px;
  }
  &-fields {
    padding: 1rem;
    flex-grow: 1;
  }
  &-footer-button {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
