@import "styles/config";
@import "client";

.receiptDetail {
  margin-bottom: 5rem;

  &-title {
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem;

    &-header,
    &-subheader {
      display: inline-block;
      margin-bottom: 1rem;
    }
    &-detail {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding-bottom: 0.5rem;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
    }
  }

  &-map {
    &-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 1rem;
    }
    height: 15rem;
    width: 100%;
    margin: 0rem 0 1rem 0;
    padding: 1rem;
  }

  &-items {
    padding: 1rem;
  }
  &-summary {
    padding: 1rem;
    bottom: 5rem;
    width: 100%;
    background-color: $white;

    &-title {
      display: flex;
      justify-content: center;
      margin-bottom: 0.75rem;
    }
    &-detail {
      &-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }
    }
  }

  &-footerButton {
    background-color: $white;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  @extend %receiptDetail !optional;
}
