@import "styles/config";
@import "../modifierItem-grid.scss";

.modifierItem {
  border-radius: 0.25rem;
  background-repeat: no-repeat;
  background-size: auto auto;
  background-position: bottom;
  list-style: none;
  @include media-breakpoint-up(sm) {
    min-height: 13rem;
  }
  @include media-breakpoint-up(md) {
    background-size: 100% auto;
  }
  @include media-breakpoint-up(lg) {
    background-size: 80% auto;
  }
  &-nestedSelector {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    margin-top: auto;
    text-align: center;
  }
}