@import "styles/config";
@import "client";

.card {
  background: $white;
  transition: box-shadow 1s ease-in-out;

  &-shadow {
    box-shadow: 0 12px 19px -6px $white;
  }

  &-title {
    border-bottom: 1px solid #d8d8d8;
  }

  &-body {
    border-bottom-style: solid;
    &:last-child {
      border-bottom: none;
    }
  }

  @extend %card !optional;
}

.bordered {
  border-bottom-width: 1.5px;
}

.clickable {
  cursor: pointer;
}
