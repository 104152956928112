.textarea-container {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.875rem;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}

.textarea-container .textarea-suffix {
  position: absolute;
  top: 40%;
  z-index: 2;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.textarea-suffix {
  right: 0px;
  height: 100%;
}

.textarea-suffix > img,
.textarea-suffix > i {
  height: 100%;
}

.textarea-container .textarea:disabled {
  color: rgba(0, 0, 0, 0.25);
}

.textarea {
  width: 100%;
  padding: 15px;
  padding-right: 30px;
  letter-spacing: 0;
  text-decoration: none;
  border: none;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  background: transparent;
  &-exceedText {
    color: red;
  }
  &-exceedArea {
    @extend .textarea;
    border: 1px red solid;
    background-color: #fcece8;
  }
}

.textarea:focus {
  outline: none !important;
  box-shadow: none;
}

.resize-none {
  resize: none;
}

@import "styles/theme.module.scss";
