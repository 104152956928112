$trackingProgressPadding: 1rem;

.segment,
.driverInfo {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.segment {
  &-container {
    padding: $trackingProgressPadding;
    &--icon,
    &--title &--description {
      display: block;
    }
  }
}

.driverInfo {
  &-container {
    line-height: 1.5rem;
    &--labelContainer {
      display: block;
    }
  }
}

.tracking {
  &-container {
    display: flex;
    padding: $trackingProgressPadding;
  }
}
