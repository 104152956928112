@import "styles/config";
@import "../modifierItem-grid.scss";

.modifierItem {
    text-align: center !important;
    &-remove {
        top: 0.25rem !important;
        left: 0.25rem !important;
        transform: none !important;
    }
    &-content {
        padding: 2rem 1rem !important;
    }
}