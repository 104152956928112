.buttonNext,
.buttonBack {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  bottom: 20%;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}

.buttonNext {
  right: 2%;
  transform: rotate(180deg);
}

.buttonBack {
  left: 2%;
}


@import "styles/theme.module.scss";