.wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 1000;
}

.shade {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal {
  position: absolute;
  width: 500px;
  background: white;
  border-radius: 10px;
}
