@import "styles/config";
@import "client";

.tableNumber {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;
  
  &-image {
    height: 100%;
    max-height: 4rem;
    margin: 0 auto 1rem auto;
    @extend %tableNumber-image !optional
  }
  &-header,
  &-location,
  &-title,
  &-description,
  &-description2 {
    margin: 0 auto 1rem auto;
  }
  &-header,
  &-location,
  &-title,
  &-information,
  &-description,
  &-description2 {
    text-align: center;
  }
  &-fields {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    margin-bottom: 0.5rem;
    & > div {
      margin: 1rem;
    }
  }
  &-information {
    padding-top: 1rem;
    white-space: pre;
  }
  &-input {
    text-align: center;
    border: none;
    @extend %tableNumber-input !optional;
  }
  @extend %tableNumber !optional;
}
