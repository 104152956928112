@import "styles/config";
@import "client";

.giftCardFieldWrapper {
  div:first-child {
    margin-bottom: 0.5rem;
  }

  div > div > input {
    margin-bottom: 0.5rem;
  }
}

.fields {
  &-button {
    height: 3rem;
    margin: 0rem -1rem;
    padding-bottom: 1.5rem;
  }
}
