@import "../base.module.scss";

.empty {
  position: relative;
  height: 55vh;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 300px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 2'><circle cx='1' cy='1' r='1' fill='%23f5f5f5'/></svg>");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  & .content {
    z-index: 5;
    
    .artImg {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
