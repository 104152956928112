.bank-loyalty-section {
  padding: 1.25rem 1rem;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

.bank-loyalty-cards {
  padding-bottom: 1.5rem;
  width: 100%;
}
.reward-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
}

.reward-icon {
  border-radius: 100%;
  height: 4.5rem;
  width: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 24em) {
    width: 3.5rem;
    height: 3.5rem;
  }
  &--points {
    @media screen and (max-width: 24em) {
      font-size: 1.2rem;
    }
  }
}

.reward-points {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  &--points {
    margin-bottom: 0.3rem;
    font-size: 2rem;
    font-weight: bold;
  }
}

.reward-image {
  &--img {
    width: 100%;
  }
}

.bankCard {
  &--reward {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &--desc {
    margin-bottom: 0.5rem;
  }
  &--learn {
    margin-bottom: 1rem;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #717171;
  }
  &--link {
    padding-left: 0;
    text-decoration: underline;
    font-size: 0.8rem;
  }
  &--info {
    margin-bottom: 1rem;
  }
  &--divider {
    border-bottom: 1px solid black;
    width: 100%;
  }
  &--button {
    border: 1px solid black;
    border-radius: 20px;
    padding: 0px 16px;
    text-transform: lowercase;
    font-size: 0.8rem;
    &:active {
      opacity: 0.5;
    }
  }
  &--content {
    width: 100%;
  }
}

.container {
  display: flex;
  padding: 1rem 1rem 0rem 1rem;
}

.bank-loyalty-title {
  margin-bottom: 10px;
}

.drawer {
  &--container {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &--dialogue {
    text-align: center;
    margin-bottom: 20px;
    padding: 0px 4rem;
    @media screen and (max-width: 24em) {
      padding: 0;
    }
  }
  &--button {
    padding: 10px 0px;
  }
}
