@import "styles/config";
@import "client";

.modifierItem {
  display: flex;
  flex-direction: row;
  height: 120px;
  margin-bottom: 20px;
  padding: 10px 5px;
  cursor: pointer;
  position: relative;

  &-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &-name {
    padding: 1px 10px;
    line-height: 1.2rem;
    flex-grow: 1;
  }

  &-desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex-grow: 3;
  }

  &-price {
    text-align: right;
    flex-grow: 1;
  }

  &-remove {
    position: absolute;
    top: -10px;
    left: -10px;
  }

  &-selectors {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-quantity {
    &--text {
      display: flex;
      flex-direction: row;
      height: 40px;
      width: 40px;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin: 0px 7.5px;
    }
    &-btn {
      height: 40px;
      width: 40px;
      text-align: center;
      @include media-breakpoint-up(md) {
        margin: 0px 5px;
      }
    }
  }

  @extend %modifierItem !optional;
}
