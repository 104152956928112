.iframe {
  margin: 0;
  padding: 0;
  border: 0;
}

.hr {
  height: 1px;
  border: 0;
  background-color: #ccc;
  margin-top: 2px;
}

.error {
  width: 100%;
  margin-top: 16px;
  font-size: 0.875rem;
  color: red;
  font-weight: 500;
  text-align: center;
  opacity: 0.8;
}
