.option {
  display: flex;
  flex-direction: row;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-top-width: 0px !important;
  margin: 1rem 0 1rem 0;
  pointer-events: all;
  &:hover {
    cursor: pointer;
  }
  &,
  &:active,
  &:focus,
  &:hover,
  &:visited {
    pointer-events: all;
  }

  &-left {
    display: flex;
    flex-direction: column;
  }

  &-name {
    white-space: pre-wrap;
  }

  &-arrow {
    margin: 0 0.25rem 0 auto;
    transition: transform 0.3s linear;
    will-change: transform;
    &--active {
      transform: rotate(180deg);
    }
    &--inactive {
      transform: rotate(0deg);
    }
  }

  &-drawer {
    overflow: hidden;
    will-change: height, opacity;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    &--visible {
      opacity: 1;
    }
    &--hidden {
      opacity: 0;
    }
  }
}
