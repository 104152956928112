@import "styles/config";
@import "client";
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.content > div:first-child {
  width: 90%;
  text-align: center;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
}

.title {
  padding: 10px 15px;
}

.divider {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 15px;
}

.footer {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
}

.footer img {
  width: 100%;
}

.bar-con {
  background-color: $background-gray;
  height: 0.5em;
  margin-bottom: 1em;
  border-radius: 7px;
  width: 90%;
  margin: 15px auto;
}

.bar {
  float: left;
  height: 100%;
  width: 40%;
  border-radius: 7px;
  background-color: #60c2a9;
  -webkit-animation: loading 10s linear infinite;
  animation: loading 20s linear;
}

@-webkit-keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 40%;
  }
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 40%;
  }
}

@media screen and (min-width: 60em) {
  .content > div:first-child {
    width: 75%;
  }
}

@media screen and (min-width: 72em) {
  .content > div:first-child {
    width: 60%;
  }
}

@media screen and (min-width: 84em) {
}

@media screen and (min-width: 96em) {
}
