@import "styles/config";
@import "client";

.itemDetailsGroups {
  &-title {
    border-right-width: 0px !important;
    border-left-width: 0px !important;
    border-top-width: 0px !important;
    margin: 1rem;
  }
  &-item {
    padding: 10px 5px; // Not rem to match existing style
    margin-bottom: 20px;
    height: 7.5rem;
    display: flex;
    flex-direction: row;
    @media screen and (min-width: 36em) {
      height: 8rem;
    }
    & .image-container {
      display: flex;
      align-items: center;
      height: 100%;
      & .image {
        max-width: 100px;
        object-fit: contain;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      & .name {
        padding: 1px 10px;
        line-height: 1.1rem;
        flex-grow: 1;

        @media screen and (min-width: 36em) {
          padding: 1px 8px;
        }
      }
      & .desc {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        flex-grow: 3;
      }
      & .price {
        text-align: right;
        flex-grow: 1;
      }
    }

    &:hover {
      cursor: pointer;
    }
    &--circle {
      margin-left: 1rem;
      border-radius: 100%;
      width: 1.875rem;
      height: 1.875rem;
    }
  }
  @extend %itemDetailsGroups !optional;
}
@media screen and (min-width: 36em) {
  .info {
    flex-direction: row;
  }
}

/* Tablet 768px */
@media screen and (min-width: 48em) {
  .info {
    flex-direction: column;
  }
}
