@import "styles/config";
@import "client";

.modifierItem {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 0 1rem;
  padding: 0.5rem 1.5rem;

  &-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
  }

  &-content {
    &--name {
      flex-grow: 2;
      line-height: 1.5;
    }

    &--price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 10px 0px;
      flex-grow: 1;
      vertical-align: middle;

      &-value {
        margin-right: 10px;
      }
    }
  }

  &-selectors {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    *:not(:last-child) {
      margin-right: 10px;
    }
  }

  &-btn {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-nestedSelector {
    color: #717171;
    font-size: 1.75rem;
    line-height: 1rem;
    padding: 0.5rem;
    &::after {
      content: "\003E";
    }
  }

  @extend %modifierItem !optional;
}
