@import "styles/config";
@import "client";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container > *:nth-child(2) {
  /* height: 100%; */
  padding: 7.5px;
}

.container > *:nth-child(3) {
  flex-grow: 1;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}
