@import "styles/config";
@import "client";

.option {
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 0;
  &-title {
    padding: 1rem 1rem 0 1rem;
  }

  &-content {
    border-right-width: 0px;
    border-left-width: 0px;
    border-top-width: 0px;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
    text-decoration: none;
    list-style: none;
    padding: 0;
  }

  &-line {
    text-align: left;
    margin: 0 auto;
  }

  &-name {
    white-space: pre-wrap;
  }
  &-lineText {
    position: relative;
    display: inline-block;
  }

  &-error {
    z-index: 9;
  }

  @extend %option !optional;
}
