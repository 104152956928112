// Colors
$white: #fff !default;
$black: #000 !default;

$background: #f5f5f5 !default;
$background-bar: #f8f9fa !default;
$background-gray: #f8f9fa !default;
$drawer-background: #fff !default;
$drawer-mask-background: #000 !default;
$dialog-mask-background: rgba(0, 0, 0, 0.1);

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
) !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$accentLight: $blue !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
) !default;

$theme-color-interval: 8% !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark".
// Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3 !default;

$color-contrast-dark: $gray-900 !default;
$color-contrast-light: $white !default;

$blue-100: tint-color($blue, 8) !default;
$blue-200: tint-color($blue, 6) !default;
$blue-300: tint-color($blue, 4) !default;
$blue-400: tint-color($blue, 2) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 2) !default;
$blue-700: shade-color($blue, 4) !default;
$blue-800: shade-color($blue, 6) !default;
$blue-900: shade-color($blue, 8) !default;

$indigo-100: tint-color($indigo, 8) !default;
$indigo-200: tint-color($indigo, 6) !default;
$indigo-300: tint-color($indigo, 4) !default;
$indigo-400: tint-color($indigo, 2) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 2) !default;
$indigo-700: shade-color($indigo, 4) !default;
$indigo-800: shade-color($indigo, 6) !default;
$indigo-900: shade-color($indigo, 8) !default;

$purple-100: tint-color($purple, 8) !default;
$purple-200: tint-color($purple, 6) !default;
$purple-300: tint-color($purple, 4) !default;
$purple-400: tint-color($purple, 2) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 2) !default;
$purple-700: shade-color($purple, 4) !default;
$purple-800: shade-color($purple, 6) !default;
$purple-900: shade-color($purple, 8) !default;

$pink-100: tint-color($pink, 8) !default;
$pink-200: tint-color($pink, 6) !default;
$pink-300: tint-color($pink, 4) !default;
$pink-400: tint-color($pink, 2) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 2) !default;
$pink-700: shade-color($pink, 4) !default;
$pink-800: shade-color($pink, 6) !default;
$pink-900: shade-color($pink, 8) !default;

$red-100: tint-color($red, 8) !default;
$red-200: tint-color($red, 6) !default;
$red-300: tint-color($red, 4) !default;
$red-400: tint-color($red, 2) !default;
$red-500: $red !default;
$red-600: shade-color($red, 2) !default;
$red-700: shade-color($red, 4) !default;
$red-800: shade-color($red, 6) !default;
$red-900: shade-color($red, 8) !default;

$orange-100: tint-color($orange, 8) !default;
$orange-200: tint-color($orange, 6) !default;
$orange-300: tint-color($orange, 4) !default;
$orange-400: tint-color($orange, 2) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 2) !default;
$orange-700: shade-color($orange, 4) !default;
$orange-800: shade-color($orange, 6) !default;
$orange-900: shade-color($orange, 8) !default;

$yellow-100: tint-color($yellow, 8) !default;
$yellow-200: tint-color($yellow, 6) !default;
$yellow-300: tint-color($yellow, 4) !default;
$yellow-400: tint-color($yellow, 2) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 2) !default;
$yellow-700: shade-color($yellow, 4) !default;
$yellow-800: shade-color($yellow, 6) !default;
$yellow-900: shade-color($yellow, 8) !default;

$green-100: tint-color($green, 8) !default;
$green-200: tint-color($green, 6) !default;
$green-300: tint-color($green, 4) !default;
$green-400: tint-color($green, 2) !default;
$green-500: $green !default;
$green-600: shade-color($green, 2) !default;
$green-700: shade-color($green, 4) !default;
$green-800: shade-color($green, 6) !default;
$green-900: shade-color($green, 8) !default;

$teal-100: tint-color($teal, 8) !default;
$teal-200: tint-color($teal, 6) !default;
$teal-300: tint-color($teal, 4) !default;
$teal-400: tint-color($teal, 2) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 2) !default;
$teal-700: shade-color($teal, 4) !default;
$teal-800: shade-color($teal, 6) !default;
$teal-900: shade-color($teal, 8) !default;

$cyan-100: tint-color($cyan, 8) !default;
$cyan-200: tint-color($cyan, 6) !default;
$cyan-300: tint-color($cyan, 4) !default;
$cyan-400: tint-color($cyan, 2) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 2) !default;
$cyan-700: shade-color($cyan, 4) !default;
$cyan-800: shade-color($cyan, 6) !default;
$cyan-900: shade-color($cyan, 8) !default;
