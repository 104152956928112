@import "styles/config";
@import "client";

.packingGroup {
  border: none;
  margin-bottom: 25px;

  &-title {
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    padding: 0px 1rem 0.75rem 1rem;
  }

  &-items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @extend %packingGroup !optional;
}
