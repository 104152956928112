@import "styles/config";
@import "client";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: calc(100vh - 9rem);
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  @include media-breakpoint-up(md) {
    padding: 4.5rem 1rem;
  }
}

.giftCard {
  &,
  &-left--value {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
  }
  height: 100%;
  min-height: calc(100vh - 9rem);
  max-width: 72em;
  margin: 1rem auto;

  &--logo {
    margin: 0 auto;
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 100%;
    }
  }

  &-left,
  &-right {
    &--container {
      width: 100%;
      max-width: 30rem;
      margin: 0 auto;
      @include media-breakpoint-up(md) {
        max-width: 40rem;
      }
    }
  }
  &-left,
  &-right,
  &-right--header,
  &-right--field {
    display: flex;
    flex-direction: column;
  }
  &-left {
    margin-bottom: 2rem;
    padding: 2rem 1rem 0 1rem;
    @include media-breakpoint-up(md) {
      margin: 0;
      padding: 2rem 4rem 0 4rem;
    }
    text-align: center;
    &--image {
      flex-grow: 1;
      & img {
        width: 100%;
        max-width: 20rem;
        margin-top: 2rem;
        @include elevation(2);
        border-radius: 0.25rem;
      }
    }
    &--value {
      transform: translate(0, -50%);
      display: flex;
      justify-content: center;
      margin: 0 0 2rem 0;
      & button {
        padding: 0.5rem 2rem;
        cursor: default;
      }
    }
    &--number {
      padding: 0.5rem;
      margin: 1rem 0 2rem 0;
      & span {
        &:not(:first-child) {
          margin-left: 0.5rem;
        }
      }
    }
    &--button {
      transform: translateY(50%);
      & button {
        padding: 1rem 4rem;
        & span {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          position: relative;
        }
        & .icon {
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          transform: translateY(100%);
          & svg {
            margin: 0 auto;
          }
        }
        & .text,
        & .icon {
          transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out;
          will-change: transform, opacity;
        }
        &.success {
          & .text {
            opacity: 0;
            transform: translateY(-100%);
          }
          & .icon {
            opacity: 1;
            transform: translateY(0%);
          }
        }
      }
    }
  }
  &-top {
    align-self: flex-start;
    justify-self: flex-start;
  }
  &-right {
    padding: 2rem 1rem;
    @include media-breakpoint-up(md) {
      padding: 2rem 4rem;
    }
    &--header,
    &--field {
      padding: 0.5rem 0;
      margin: 0 0 1rem 0;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      flex-direction: column;
    }
    &--field {
      & > span:first-child {
        margin: 0 0 0.5rem 0;
      }
      & > span:last-child {
        padding: 0 0.5rem;
      }
    }
    &--app {
      width:100%;
      margin: 1.5rem 0 1rem 0;
      display: inline-block;
      flex: wrap;
      padding: 0 0 0.8rem 0;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
    }
    &--message {
      padding: 0.5rem;
      margin: 0.5rem 0 0 0;
      min-height: 10rem;
    }
  }

  @extend %giftCard !optional;
}
