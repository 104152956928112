.option {
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-top-width: 0px !important;
  margin-bottom: 20px;
  margin-top: 10px;
}

.name {
  white-space: pre-wrap;
}
