fieldset {
  border: none;
  margin: 0;
}

.spf-field {
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
}

.spf-input-text {
  display: block;
  width: 100%;
  max-width: 18rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
}

.spf-fs-name .spf-field {
  width: 100%;
  max-width: 19rem;
  margin-right: 0.75rem;
}

.spf-field-lname {
  margin-right: 0;
}

.spf-field-cc {
  height: 3.5rem;
}

.spf-field-group {
  display: inline-block;
}

.spf-month {
  width: 100%;
  max-width: 4rem;
}

.spf-year {
  width: 100%;
  max-width: 7rem;
  margin-right: 1rem;
}

.spf-zip {
  width: 100%;
  max-width: 7rem;
}

.spf-exp-divider {
  display: inline-block;
  margin: 0 0.75rem;
  font-size: 1.75rem;
  position: relative;
  top: 0.25rem;
}

@media screen and (max-width: 365px) {
  .spf-zip {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 400px) {
  .spf-zip {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 625px) {
  .spf-form {
    width: calc(100% - 20rem);
  }
}

@media screen and (max-width: 1490px) {
  .spf-zip {
    margin-top: 1rem;
  }
}
