@import "styles/config";
@import "client";

.rewardCard {
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
  min-width: 15rem;

  &--content {
    white-space: pre-line;
    padding: 1rem 2.5rem 1rem 1rem;
    flex-grow: 1;
  }

  &--click {
    cursor: pointer;
  }

  &-img--container {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 2.5rem;
  }

  &-img--image {
    width: 70%;
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }

  @extend %rewardCard !optional;
}
