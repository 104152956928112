@import "styles/config";
@import "client";
.complete {
  overflow-y: auto;

  &-content {
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: 2rem;
    margin-bottom: 3rem;

    &-information {
      width: 90%;
      height: 100% !important;
      justify-content: start !important;
    }

    &-img {
      max-width: 70%;
      margin: 1rem auto;
    }

    &-map {
      height: 15rem;
      width: 100%;
      div {
        height: 100%;
      }
    }

    &-details {
      text-align: left;
      width: 100%;
    }

    &-items {
      padding: 10px 0px 0px 0px;
      margin: 0px;
      list-style: none;
      text-decoration: none;
      clear: both;
    }

    &-timeContainer {
      margin: 1rem 0;
    }

    &-barCon {
      background-color: $background-bar;
      height: 0.5em;
      margin-bottom: 1em;
      border-radius: 1rem;
    }

    &-bar {
      float: left;
      height: 100%;
      width: 40%;
      -webkit-animation: loading 10s linear infinite;
      animation: loading 20s linear;
    }

    &-divider {
      height: 2px;
    }

    &-title {
      text-align: left;
      margin: 2rem 0rem 1rem 0;
    }
  }

  &-footer {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
