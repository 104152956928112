@import "styles/config";
@import "client";

.cartItem {
  &-desc {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    border-bottom-width: 1px !important;
    line-height: 1.5;
    &--mods {
      display: flex;
      flex-direction: column;
    }
    &--notes {
    }
    &:last-child {
      border-bottom: 0px !important;
    }
  }

  @extend %cartItem !optional;
}

.info {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  height: 100%;
  flex: 1 0.75 100%;
}

.title {
  flex-grow: 0;
  display: flex;
}

.title span:first-child {
  flex-grow: 1;
}
.title span:nth-child(2) {
  flex-grow: 0;
}

.actions {
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
}

.card {
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  padding: 1rem;
  margin-bottom: 1rem;
}

.image {
  flex-shrink: 1.5;
  width: 100%;
  min-height: 100%;
}

.image > div:first-child {
  height: 100%;
  background-color: $background-gray;
  border-radius: 10px;
}

.image > div {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
