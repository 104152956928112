@import "styles/config";
@import "client";

.orderHistory {
  &-navigation {
    display: inline-block;
    padding: 1rem;
  }
  &-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    &-date {
      align-self: flex-start;
      margin: 1rem 0rem 0.5rem 0rem;
      display: inline-block;
    }
  }

  @extend %orderHistory !optional;
}
