.link {
  text-decoration: none;
  text-align: center;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}
