@import "styles/config";
@import "client";

.modifierItem {
  margin: 10px 0px;
  cursor: pointer;
  padding: 10px;
  list-style: none;
  &-container {
    position: relative;
    display: flex;
    text-decoration: none;
    width: 100%;
    align-items: center;
  }
  &-name {
    flex-grow: 2;
    line-height: 1.5;
    -webkit-appearance: button-bevel;
    padding: 0.5rem;
  }

  &-content {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    &--price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
      vertical-align: middle;
      padding: 0.5rem;
      & > span {
        margin-right: 0.625rem;
      }
    }
  }

  &-selectors {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 0.5rem;

    *:not(:last-child) {
      margin-right: 0.625rem;
    }

    &-quantityBtn {
      justify-content: flex-end;
      z-index: 10;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      &--plus:before {
        top: 6.5%;
        right: 29%;
        position: absolute;
      }
      &--minus:before {
        position: absolute;
        top: 12.5%;
        right: 27%;
        margin: auto 2px;
        height: 2px;
      }
      &--radio {
        z-index: 2;
      }
    }
  }
  @extend %modifierItem !optional;
}
