@import "styles/config";

$accentDark: #e37f68;
$alternateGray: #ebe6d2;
$baseGray: #505962;
$accentLight: #2274af;
$accentOrange: #f3ebdf;
$baseBlack: #212121;
$lightGray: #88919a;

%location {
  .location {
    &-container {
      background-color: $alternateGray;

      &--header {
        background-color: $alternateGray;
        & .body {
          &-title {
            margin-right: 0 !important;
            @include media-breakpoint-up(md) {
              width: 60% !important;
            }
          }
          &-art {
            top: 0.5rem !important;
            right: 0.5rem !important;
          }
        }
      }
    }
  }
}

%locationInfo {
  .locationInfo-body {
    line-height: 1.5;
  }
  @include media-breakpoint-up(md) {
    width: 50% !important;
  }
}

.rc-tabs-bar {
  border-bottom: none !important;
}

.rc-tabs-tab {
  border-bottom: 2px solid $lightGray;
}
.rc-tabs-tab-active {
  border-bottom: 3px solid $accentDark;
}

%locationCard {
  border: 2px solid $baseBlack;
  transition: all 1s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 8px rgba(95, 95, 95, 0.5) !important;
  }
  .card {
    button {
      width: 100%;
      justify-content: space-between;
      span {
        flex-grow: 0;
      }
    }
  }
}

%nav {
  box-shadow: 0px 4px 4px rgba(95, 95, 95, 0.1) !important;
}

%group {
  &-info {
    margin-top: 1rem !important;
    margin-bottom: 0;
  }
}

%header {
  .header-wrapper {
    &--left {
      & .logo {
        &:not(:last-child) {
          border-right: 2px solid $baseBlack;
        }
      }
    }
  }
}

.profile {
  background-color: $alternateGray !important;
  & > div {
    margin: 1rem 2.5rem !important;
    width: 100% !important;
  }
  &-section {
    border: 2px solid $baseBlack !important;
    border-radius: 5px !important;
    &-container {
      padding: 0 1rem !important;
    }
    &-title {
      background-color: $alternateGray !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px !important;
    }
    &-content {
      background-color: $alternateGray !important;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
  }
}

%profile {
  .profile-form {
    background-color: $alternateGray;
  }
}

.loyaltyRewardSection {
  background-color: $alternateGray !important;
  border: none !important;
}

%orderHistory {
  .orderHistory {
    &-content {
      &-date {
        font-family: "FivoSansModern-Heavy" !important;
        font-size: 1.25rem;
      }
    }
  }
}

%group {
  .group-info {
    margin-bottom: 0;
    &-container {
      margin: 0 1rem !important;
      border-bottom: 2px solid $baseGray;
    }
    @include media-breakpoint-up(md) {
      padding: 2rem 0 1rem;
    }
  }
}

%modifierItem {
  padding: 0 1rem !important;
  .modifierItem {
    &-radio-wrapper {
      box-shadow: none !important;
    }
    &-content {
      &--price {
        padding: 0.625rem !important;
      }
    }
  }
}

%footerButton {
  background-color: $alternateGray !important;
  border-radius: 0;
}

%selectors {
  .quantitySelector {
    &-container {
      .quantity-btn {
        & span {
          font-size: 1rem !important;
        }
      }
    }
  }
}

%option {
  .option {
    &-items {
      flex-direction: column !important;
    }
  }
}

.form .fields-container {
  background-color: $alternateGray !important;
}

.form .terms {
  background-color: $alternateGray !important;
}

%cartItem {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

%loginSignup {
  .loginSignup {
    &-header {
      padding: 1.5rem 0 0 0 !important;
      &-content {
        padding: 0 1rem !important;
        & div {
          display: block;
          padding: 0px !important ;
        }
      }
    }
  }
}

%giftCard {
  .giftCard {
    &--logo {
      height: 7.8125rem;
    }
    &-left {
      &--value {
        transform: translateY(-175%);
        margin: 0rem;
      }
    }
  }
}

%upsellItem {
  box-shadow: 0px 4px 8px 0px #00000014;
  .upsellItem {
    &-info {
      &__actions {
        border-top: 0 !important;
        justify-content: flex-start !important;
      }
    }
  }
}

%cart {
  .cart-content {
    background: $alternateGray;
    &--items li {
      border-bottom: 2px solid #d8d8d8;
    }
  }
}

%order {
  .order {
    &-header {
      z-index: 1;
      box-shadow: 0px 4px 4px rgba(95, 95, 95, 0.1) !important;
    }
  }
}

%itemDetails {
  .itemDetails {
    &-back {
      box-shadow: none;
      height: 2rem !important;
      width: 2rem !important;
      background: none !important;
      padding: 0 !important;
      display: block !important;
      & div:first-child {
        display: block !important;
      }
      & img {
        height: 2rem;
        width: 2rem;
      }
    }
  }
}

.complete-content-timeContainer {
  border-bottom: 1px solid #88919a !important;
}

.footerButton {
  box-shadow: 0px -4px 4px 0px rgba(95, 95, 95, 0.1);
}

%itemDetailsHeader {
  padding: 2rem 0 !important;
  .itemDetailsHeader {
    &-content {
      div:last-child {
        div:last-child {
          margin-bottom: 0 !important;
        }
        span:last-child {
          margin-bottom: 0 !important;
        }
      }
      &-diet {
        margin: 0 1.5rem !important;
      }
    }
  }
}

%itemDetails-tabs {
  .itemDetails-tabs {
    &-nav-container {
      .nav {
        border-top: 1px solid $baseGray !important;
        border-bottom: 1px solid $baseGray !important;

        .nav-item[data-active="1"] {
          border-bottom: 2px solid $accentDark;
        }
      }
    }
  }
}

%tipsContainer {
  .tipsContainer {
    &-wrapper {
      &--singleTip {
        border: 2px solid $accentDark !important;
      }

      &--customAmountSelected {
        padding: 0.7rem 1rem !important;
        color: #fff !important;
        background-color: $accentDark !important;
      }
    }
  }
}

%orderItem {
  .orderItem {
    &-actions {
      border-top: none;
      padding: 0.5rem 0;
      &-btn {
        margin-left: 0.625rem !important;
      }
    }
  }
}
