.background {
  padding: 20px;
}

.confirm {
  width: 100%;
  text-align: center;
}

.dialogue {
  margin-bottom: 15px;
}
