@import "styles/config";
@import "client";

.cart {
  position: relative;
  overflow-y: auto;
  &-content {
    padding: 1rem;
    flex-grow: 1;
    overflow-y: auto;
  }
  &,
  &-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }
  @include media-breakpoint-up(sm) {
    &-content {
      overflow: hidden;
      overflow-y: auto;
    }
  }
  &-content {
    background:
		/* Shadow covers */ linear-gradient(
        $white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), $white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background:
		/* Shadow covers */ linear-gradient(
        $white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), $white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background-repeat: no-repeat;
    background-color: $white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
    &--schedule {
      margin: 1rem 0;
    }
    &--clear {
      display: flex;
      justify-content: flex-end;
      margin: 1rem 0;
      width: 100%;
    }
    &--items {
      padding: 0;
      margin: 0;
    }
  }
  &-footer {
    width: inherit;
    bottom: 0;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    left: 0;
    z-index: 99;
    & div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @extend %cart !optional;
}