@import "styles/config";
@import "client";

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 1rem;
  flex-grow: 1;
  overflow-y: scroll;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
}

.bottomSheetOverlay {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  height: 100vh;
  top: -98vh;
  width: 100%;
  z-index: 9999;
  left: 0rem;
  right: 0rem;
}

.footer {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -17px 36px -26px rgba(76, 76, 76, 0.53);
  z-index: 9;
}

.price-container {
  padding: 1rem;
  flex-grow: 1;
  position: relative;
  bottom: 0;
}

.checkout {
  &-summary {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      &:not(:first-child) {
        padding: 1rem 0 0 0;
      }
    }
    &--clear {
      cursor: pointer;
      position: relative;
      bottom: 0.5rem;
    }
    &-information {
      padding: 0px 0.25rem;
      text-align: center;
    }
  }
}

.promo,
.tip {
  display: flex;
  align-items: center;
}

.promo > span,
.tip > span {
  flex-grow: 10;
}
.promo > span:last-child,
.tip > div:last-child {
  flex-grow: 1;
  flex-basis: 50px;
  @extend %tipAmount !optional;
}

.promo > span:last-child > *:first-child {
  padding: 0px;
}

.tip {
  padding-top: 1rem;
}

.tip > span:last-child {
  text-align: right;
}

.tip > span:first-child {
  width: 89%;
  & > .tip-label {
    margin-bottom: 0.6rem;
  }
  & > .tip-label,
  .tip-group {
    display: block;
  }
  @media only screen and (min-width: 30em) {
    width: auto;
    & > .tip-label {
      margin-bottom: 0;
      margin-right: 0.75rem;
    }
    & > .tip-label,
    .tip-group {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

@media screen and (max-width: 36rem) {
  .tip-group {
    label {
      width: auto;
      font-size: 0.75rem !important;
      padding: 0.25rem 0.5rem;
    }
  }
}

.tip-input {
  margin: 0px;
}

.tip-btn {
  padding: 0.25rem 0.75rem;
  display: inline-block;
}

.tip-group {
  @extend %tipGroup !optional;
}

.tip-group > .tip-btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.tip-group > .tip-btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.information {
  margin-left: 0.75rem;
  text-align: center;
}

.cards-container {
  -webkit-box-shadow: 0px 1px 24px -9px rgba(51, 51, 51, 1);
  -moz-box-shadow: 0px 1px 24px -9px rgba(51, 51, 51, 1);
  box-shadow: 0px 1px 24px -9px rgba(51, 51, 51, 1);
  background: white;
  padding: 25px;
}

.cards-container .card-item:first-child {
  margin: 15px 0px;
}

.card-item {
  height: 25px;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid #f7f7f7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.card-item span:first-child {
  text-transform: uppercase;
}

.card-item span {
  padding-right: 20px;
}

@media screen and (min-width: 48em) {
  .price-container > div {
    margin-bottom: 15px;
  }
}
