@import "styles/config";
@import "client";

$modifierItem-border-color: #f5f5f5;

.modifierItem {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: auto 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: calc(100% - 1rem);
  margin-bottom: 1rem;
  min-height: 12rem;
  position: relative;
  @include media-breakpoint-up(sm) {
    min-height: 13rem;
  }
  @include media-breakpoint-up(lg) {
    min-height: 14rem;
  }
  &.row {
      flex-direction: row;
      padding: 0.5rem;
      & .modifierItem {
        &-image {
          padding: 0.5rem;
        }
        &-content {
          padding: 0.5rem;
          &--name {
            margin-bottom: 0.25rem;
          }
        }
      }
  }
  &-image {
    width: 100%;
    height: auto;
  }
  &-content {
    padding: 1rem;
    flex-grow: 1;
    &--name, &--desc {
      margin-bottom: 0.5rem;
    }
  }
  &-remove {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }
  &-selectors {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    margin: -0.75rem 0 0.25rem 0;
    &--qty {
      display: flex;
      flex-direction: column;
      height: 50px;
      width: 50px;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin: 0 1rem;
      @media screen and (min-width: 60em) {
        margin: 0px 5px;
      }
      @media screen and (min-width: 72em) {
        margin: 0px 2px;
      }
      @media screen and (min-width: 84em) {
        margin: 0 1rem;
      }
    }
    &--btn {
      height: 30px;
      width: 30px;
      text-align: center;
    }
  }
  
  &-selectors {
    margin-top: auto;
  }

  &-nestedSelector {
    background: #fff;
    border-bottom: none !important;
    border-left: none !important;
    border-radius: 0 !important;
    border-right: none !important;
    border-top: 1px solid $modifierItem-border-color;
    margin-top: auto;
    padding: 0.25rem 0;
    text-align: center;
  }

  @extend %modifierItem !optional;
}