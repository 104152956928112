@import "styles/config";
@import "client";

.search-container {
  width: 100%;
  position: relative;
}

.search-dropdown {
  width: 100%;
  z-index: 3;
  left: 15px;
}

.search-dropdown input:focus + .search-results {
  display: block;
}

.search-results {
  max-height: 200px;
  display: initial;
}

.search-result {
  padding: 5px;
  margin-bottom: 3px;
  cursor: pointer;
  border-radius: 3px;
}

@import "styles/theme.module.scss";
