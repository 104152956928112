@import "styles/config";
@import "client";

$tooltipWidth: 250px !default;

.tooltip {
  position: relative;
  display: inline-block;
  &-icon {
    border: none;
    background: transparent;
    cursor: pointer;
    white-space: pre;
    z-index: 1 !important;
  }
  &-message {
    z-index: 999;
    position: absolute;
    white-space: normal;
    padding: 7px;
    border-radius: 3px;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    text-align: center;
    font-size: 0.675rem;
    line-height: 1.2;
    bottom: 60%;
    text-align: left;
    max-width: 15rem;
    width: max-content;

    &.right {
      left: 50%;
    }

    &.left {
      right: 50%;
    }

    &.bottom {
      top: 60%;
    }
  }
}
