.container {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.overlay[role="button"] {
  cursor: pointer;
}
