@import "styles/config";
@import "client";

.locationModal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 9999;
  overflow: hidden;

  &-deliveryTab {
    margin-top: 1.25rem;
    overflow: hidden;
    max-height: 20rem;
    input {
      padding: 1rem 1rem;
    }
  }

  &-scheduleSelect {
    &:active,
    &:focus {
      outline: none;
    }

    p {
      padding-left: 1rem;
    }
  }
  &-addressSelect {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }

  &-fieldItem {
    &:first-child {
      margin-top: 2rem;
    }

    select {
      padding: 1rem 0;
      width: 100%;
    }
  }

  .updateButton {
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.25rem;
    span {
      width: 100%;
      text-align: center;
      padding: 1rem 0;
    }
    @media screen and (max-width: 515px) {
      padding: 2rem 0;
    }
  }

  &-scheduleContainer {
    position: relative;
    height: 100%;
  }

  @extend %locationModal !optional;
}
.modal {
  background: white;
  overflow: hidden;
  width: 579px;
  min-height: 550px;
  height: max-content;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  padding: 2rem;
}

.top {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 1rem 0 2rem 0;
  justify-content: center;
}
// move this to theme file
.closeButtonWrapper {
  display: flex;
  position: absolute;
  left: 0;
  width: 2.25rem;
  height: 2.25rem;
  background: #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  color: #1a1a1a;
  justify-content: center;
  align-items: center;
  padding: 10px;

  & > button {
    padding: 0;
    span {
      padding: 0 !important;
      font-weight: 900;
      font-size: 1rem;
    }
  }
  @extend %closeButtonWrapper !optional;
}

.scheduleSelect {
}
.scheduleSelect:active,
.scheduleSelect:focus {
  outline: none;
}

.fieldItem {
  display: flex;
  flex-direction: column;
}

.updateButton {
  width: 100%;
  border-radius: 4px;
  margin-top: 2rem;
  height: 40px;
}

.closeButton {
  color: blue;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.mobileCloseButtonWrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.mobileCloseButton {
  position: absolute;
  left: 1rem;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.mobileFormHeader {
  text-transform: none;
}

@media only screen and (max-width: 768px) {
  .modal {
    width: 100%;
    height: 100%;
  }
  .updateButton {
    position: absolute;
    bottom: 30px;
  }
  .scheduleFields {
    height: 30rem;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    display: flex;
    margin: 1rem;
  }
}
