.block {
  display: block;
}

.span[type="body1_baseWarning"] {
  padding-top: 0.2rem;
}

.text {
  margin: 0;
}

@import "styles/theme.module.scss";
