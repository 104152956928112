@import "styles/config";
@import "client";

.impact {
  display: flex;
  flex-direction: column;
  position: relative;
  &-content {
    padding: 1rem 1rem;
    flex-grow: 1;
    & .tabs {
      overflow-y: auto;
      &-container {
        padding: 1.5rem 1rem;
        margin-bottom: 3rem;
      }
    }
  }

  &-footer {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -17px 36px -26px rgba(76, 76, 76, 0.53);
    z-index: 9;
  }
}
