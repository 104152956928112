@import "styles/config";
@import "client";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  @extend %collapsable !optional;
}

.title {
  flex-grow: 1;
}

.toggle {
  flex-grow: 0;
}

.content {
  transition: height 1s ease;
}

i {
  border: solid $black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.visibility-controller {
  transition: max-height 0.1s ease;
  opacity: 0;
  overflow: hidden;
  max-height: 0px;
}
.visibility-controller.isVisible {
  opacity: 1;
  max-height: 600px;
}
