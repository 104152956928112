@import "styles/config";
@import "client";

.modifierItem {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  list-style: none;
  margin: 0px;
  position: relative;
  text-decoration: none;
  width: 100%;
  outline: none;
  pointer-events: all;

  &-multiselect {
    &-container {
      position: relative;
    }
    &-button {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
    }
  }
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    &-button {
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-align: left;
      background: none;
      border: none;
    }
    &--left {
      flex-grow: 2;
      line-height: 1.5;
      padding: 0.75rem 0px;
    }
    &--right {
      pointer-events: all;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0.75rem 0px;
      flex-grow: 1;
      vertical-align: middle;
      & .price {
        margin-right: 0.75rem;
      }
      & .selectors {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        &-quantityBtn {
          border: 1px solid #000;
          height: 30px;
          width: 30px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          &:hover {
            pointer-events: auto;
          }
          &--plus:before {
            content: "+";
          }
          &--minus:before {
            content: "-";
          }
        }
        & *:not(:last-child) {
          margin-right: 0.75rem;
        }
        &-nested {
          color: #717171;
          font-size: 1.75rem;
          line-height: 1rem;
          display: flex;
          max-height: 2rem;
          max-width: 2rem;
          padding: 0.5rem;
          &:before {
            content: "\003E";
          }
        }
      }
      & .radio {
        align-self: flex-start;
        z-index: 2;
      }
    }
    &--nestedItems {
      list-style: none;
      padding-inline-start: 0;
      & li {
        padding: 0 0 0.75rem 0;
      }
    }
  }

  @extend %modifierItem !optional;
}
