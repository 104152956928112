// For now, we are only allowing color modifications because checkout is a universal product component
$checkoutTabHeader-bg: #000 !default;
$checkoutTabHeader-text: #fff !default;
$checkoutTabContent: #f5f5f5 !default;
$checkoutTabInfoLabel: #58595b !default;

@import "styles/config";
@import "client";

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkout {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  height: 100%;
  &-layout {
    width: 100%;
    overflow: hidden;
  }
  &-tip {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 1rem 0;
    & > span {
      width: auto;
      &:first-child {
        width: 89%;
        & > span {
          display: block;
        }
        @media only screen and (min-width: 30em) {
          width: auto;
          & > span {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
      &:last-child {
        text-align: right;
      }
    }
    &--input {
      margin: 0px;
      max-width: 4rem;
    }
    &--btn {
      padding: 0.1rem 0.5rem;
      display: inline-block;

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      & > span {
        @include media-breakpoint-down(md) {
          font-size: 0.85rem !important;
        }
      }
    }
    &--group {
      @extend %tipGroup !optional;
    }
  }
  &-tabs {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      & span {
        margin-right: auto;
        color: $checkoutTabHeader-text;
      }
      background: $checkoutTabHeader-bg;
      & .price {
        margin-left: auto;
        margin-right: 0;
      }
      & .check {
        margin-left: auto;
        & path {
          fill: $checkoutTabHeader-bg;
        }
        & circle {
          fill: $checkoutTabHeader-text;
        }
      }
      & .arrow {
        margin-right: 1rem;
        transform: rotate(180deg);
        transition: transform 0.3s linear;
        will-change: transform;
        & path {
          fill: $checkoutTabHeader-text;
        }
        &.closed {
          transform: rotate(0deg);
        }
      }
    }
    &--container {
      margin: 1rem;
      background: $checkoutTabContent;
      border-radius: 0.25rem;
      overflow: hidden;
    }
    &--content {
      padding: 1rem;
    }
  }
  &-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .checkout-field {
      margin-bottom: 0;
      &:first-child {
        margin-right: 1rem;
      }
    }
    & span {
      display: flex;
      flex-direction: column;
      margin-top: 0.25rem;
    }
  }
  &-field {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 0.5rem;
    & label {
      color: $checkoutTabInfoLabel;
      margin-bottom: 0.25rem;
      position: relative;
    }
    & span {
      margin-top: 0.25rem;
    }
    &--input {
      width: 100%;
      padding: 0.75rem;
      background: #ffffff;
      border: 1px solid #d6d6d6;
      border-radius: 0.25rem;
      &::placeholder {
        color: $checkoutTabInfoLabel;
        opacity: 0.3;
      }
    }
    &-tooltip {
      position: absolute;
      right: 0;
      top: 55%;
      &-icon {
        position: absolute;
        right: 0.5rem;
        bottom: 0;
      }
    }
  }
  &-radio {
    margin-bottom: 0.5rem;
  }
  &-stripeField {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    background: #ffffff;
    border: 1px solid #d6d6d6;
    border-radius: 0.25rem;
  }
  &-summary {
    display: flex;
    flex-direction: column;
    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &:not(:first-child) {
        padding: 1rem 0 0 0;
      }
    }
    &-information {
      padding: 0px 0.25rem;
      text-align: center;
    }
  }
  &-error {
    padding-top: 0.5rem;
  }
  &-footer {
    box-shadow: 0px 0.25rem 0.5rem #d8d8d8;
    border-radius: 0.25rem;
  }

  @extend %checkout !optional;
}

.paymentRequestButton {
  padding: 1rem;
}

.bottomSheetOverlay {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  height: 100vh;
  top: -98vh;
  width: 100%;
  z-index: 9999;
  left: 0rem;
  right: 0rem;
}
