@import "styles/config";
@import "client";

.nestedModifier {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: flex-end;
    z-index: 99;

    &-tint {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: #000;
    }

    &-wrapper {
        position: relative;

        width: 100%;
        margin-top: 4.5rem;

        background: #fff;
        border-radius: 0.25rem;
    }

    &-container {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    &-header {
        padding: 1rem;
    }

    &-content {
        max-height: calc(100vh - 10rem);
        overflow-y: scroll;
        overflow-x: hidden;
    }

    &-innerContent {
        flex-grow: 1;
        padding: 0rem 1.25rem 2rem 1.25rem;
    }

    &-modifiers {
        margin: 0 1rem;
        padding: 0;
    }

    @extend %nestedModifier !optional;
}
