.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  transform: translateY(62px);
  will-change: transform;
}
