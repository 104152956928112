@import "../../itemDetails.module.scss";

.itemDetails-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: 0;
  min-height: 4.06rem;
  z-index: 1;
  padding: 0.625rem 3.5rem;
}
