.section {
  padding: 1rem;
  width: 100%;
  align-items: center;
}

.section-content {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.images-row {
  align-items: center;
}

.app-store-img,
.play-store-img {
  width: 100%;
}
