.option {
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-top-width: 0px !important;
  padding: 20px 0px;
}

.list {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.name {
  white-space: pre-wrap;
}
