@import "styles/config";
@import "client";

.search-container {
  width: 100%;
  position: relative;
}

.search-dropdown {
  width: 100%;
  z-index: 3;
  left: 15px;
}

.input::placeholder {
  color: $gray-600;
}

.search-dropdown input:focus + .search-results {
  display: block;
}

.search-results {
  max-height: 200px;
  display: initial;
}

.search-result {
  padding: 5px;
  margin-bottom: 3px;
  cursor: pointer;
  border-radius: 3px;
}

.search-result.search-result-has-focus {
  color: var(--focus-text-color);
}

.field {
  margin-top: 15px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1s infinite;
}

.geo-button {
  padding: 0;
  background: none;
  border: none;
}

.geo-icon {
  cursor: pointer;
  width: 18px;
}

@import "styles/theme.module.scss";
