@import "styles/config";
@import "client";

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @extend %logoutContainer !optional;
}

.dialogue {
  text-align: center;
}

.container button {
  padding-top: 10px;
  padding-bottom: 10px;
}

.container > div:first-child {
  margin-bottom: 15px;
}
