@import "styles/config";
@import "client";

.order {
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 1rem;
  }
  &-route {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    background: $white;
  }
  @extend %order !optional;
}

.checkout {
  &-summary {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    -webkit-overflow-scrolling: auto;
    min-height: 4.75rem;
    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      &:not(:first-child) {
        padding: 1rem 0 0 0;
      }
    }
    &--right {
      padding: 0;
      text-align: right;
      white-space: nowrap;
    }
    &--discount {
      > :last-child {
        cursor: pointer;
        position: relative;
        bottom: 0.5rem;
      }
    }
    &-information {
      padding: 0px 0.25rem;
      text-align: center;
    }
  }
}

.cart {
  &-footer {
    padding: 1rem;
  }
}
