.content {
  flex-grow: 1;
}

.logo {
  position: relative;
  top: -25px;
  margin: 0 auto;
}

.logo img {
  max-width: 141px;
}
