@import "styles/config";
@import "client";

.body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0px -3px 10px rgba(243, 240, 240, 0.1);
  padding: 2rem;
  background: $white;
  @extend %bottomSheetBody !optional;
}

.bottomSheetContainer {
  padding-top: 3rem;
}

.header {
  width: 90%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: absolute;

  @media only screen and (max-width: 23.4375em) {
    width: 100%;
  }
}

.title {
  line-height: 1.5rem;
  flex-grow: 1; /* take up rest of line */
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.7rem;

  @media only screen and (max-width: 27.56em) {
    font-size: 1.45rem;
    text-align: center;
    flex-grow: 1;
  }
}

.closeTip {
  border: none;
  background: none;
  align-self: center;
  cursor: pointer;
}
