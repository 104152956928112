@import "styles/config";
@import "client";

.quantitySelector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-bottom: none !important;
  border-right: none !important;
  border-left: none !important;

  &-container {
    display: flex;
    position: relative;
    align-items: center;
    padding-right: 1rem;
    margin-right: 1rem;
    justify-content: space-between;
    .quantity-content {
      display: flex;
      flex-direction: column;
      height: 2.5rem;
      width: 2.5rem;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin: 0rem 0.5rem;
      border: none;
    }
    .quantity-btn {
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 100%;
      & span {
        display: inline-block;
        font-family: sans-serif;
        font-weight: bold;
        font-size: 2rem !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  @extend %selectors !optional;
  @extend %quantitySelector !optional;
}
