:root {
  --font-1: "EVA-CUTE";
  --font-2: "FivoSansModern-Bold";
  --font-3: "FivoSansModern-Heavy";
  --font-4: "FivoSansModern-Medium";
  --font-5: "MoonFlowerBold";
}

@font-face {
  font-family: "EVA-CUTE";
  src: url("../../clients/#{$client}/fonts/EVA-CUTE.otf") format("opentype");
}
@font-face {
  font-family: "FivoSansModern-Bold";
  src: url("../../clients/#{$client}/fonts/FivoSansModern-Bold.otf")
    format("opentype");
}
@font-face {
  font-family: "FivoSansModern-Heavy";
  src: url("../../clients/#{$client}/fonts/FivoSansModern-Heavy.otf")
    format("opentype");
}
@font-face {
  font-family: "FivoSansModern-Medium";
  src: url("../../clients/#{$client}/fonts/FivoSansModern-Medium.otf")
    format("opentype");
}
@font-face {
  font-family: "MoonFlowerBold";
  src: url("../../clients/#{$client}/fonts/MoonFlowerBold.ttf")
    format("truetype");
}
