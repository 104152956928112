@import "styles/config";
@import "client";

.footerButton {
  padding: 1rem;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  & > button {
    min-height: 55px;
    max-height: 65px;
    height: 100%;
  }
  @extend %footerButton !optional;
}
