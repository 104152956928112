.option {
  text-decoration: none;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.title {
  padding: 1rem 1rem 0 1rem;
}
