@import "styles/config";
@import "client";

.content {
  padding: 15px 15px;
  flex-grow: 1;
  overflow-y: scroll;
}

.footer {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -17px 36px -26px rgba(76, 76, 76, 0.53);
  z-index: 9;
}
