@import "styles/config";
@import "client";

.back {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
  cursor: pointer;

  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  @extend %backButton !optional;
}
