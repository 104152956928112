.itemDetailsGroups {
  &-title {
    border-right-width: 0px !important;
    border-left-width: 0px !important;
    border-top-width: 0px !important;
    margin: 1rem;
  }
  &-item {
    &,
    &--right {
      display: flex;
    }
    padding: 0.5rem 0;
    margin: 1rem;
    &:last-child {
      margin: 1rem 1rem 0 1rem;
    }
    &:hover {
      cursor: pointer;
    }
    &--left {
      display: flex;
      flex-direction: column;
      width: calc(100% * 4 / 5);
    }
    &--right {
      justify-content: flex-end;
      align-items: center;
      width: calc(100% * 1 / 5);
    }
    &--radio {
      margin-left: 0.5rem;
      z-index: 2;
    }
  }
}
