@import "styles/config";
@import "client";

//conditional dine in css classes
.containerDineIn {
  height: 100%;
}

.contentDineInWrapper {
  padding: 1rem;
  z-index: 1;
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contentDineIn {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}
.imageDineInWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.imageDineInContainer {
  display: flex;
  max-width: 8rem;
  @media screen and (min-width: 48em) {
    max-width: 22rem;
  }
  overflow: hidden;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
}
.imageDineIn {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.dividerDineIn {
  height: 0.125rem;
  margin: 1.563rem 0rem;
}

.footerDineIn {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
}
///end of css for dine in

.complete {
  overflow-y: auto;

  &-content {
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: 2rem;
    margin-bottom: 3rem;

    &-information {
      width: 90%;
      height: 100% !important;
      justify-content: start !important;
    }

    &-img {
      max-width: 70%;
      margin: 1rem auto;
    }

    &-details {
      text-align: left;
      width: 100%;
    }

    &-items {
      padding: 0.625rem 0 0 0;
      margin: 0;
      list-style: none;
      text-decoration: none;
      clear: both;
      width: 100%;
    }

    &-timeContainer {
      margin: 1rem 0;
    }

    &-barCon {
      background-color: $background-bar;
      height: 0.5em;
      margin-bottom: 1em;
      border-radius: 1rem;
    }

    &-bar {
      float: left;
      height: 100%;
      width: 40%;
      -webkit-animation: loading 10s linear infinite;
      animation: loading 20s linear;
    }

    &-divider {
      height: 2px;
    }

    &-title {
      text-align: left;
      margin: 2rem 0rem 1rem 0;
    }
  }

  &-footer {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  @extend %complete !optional;
}
