@import "styles/config";
@import "client";

$modifierItem-shadow: false !default;

.item {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1rem;
  min-height: calc(100% - 1rem);
  overflow: hidden;

  @if $modifierItem-shadow {
    @include elevation(2, 4, "hover");
  }

  &:hover {
    cursor: pointer;
  }

  &-wrapper {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  &-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    &-content {
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
    }
  }

  &-check {
    display: flex;
    justify-content: center;
    align-items: center;
    &-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    &-icon {
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
      margin-bottom: 1rem;
    }
  }

  &-image {
    position: relative;
    max-height: 4rem;
    display: flex;
    object-fit: contain;
    pointer-events: none;
  }
  &-title {
    margin-bottom: 0.5rem;
  }
  &-qty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    &--number {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      width: 4rem;
      height: 4rem;
    }
    &--btn {
      text-align: center;
      width: 40px;
      height: 40px;
    }
  }
  &-price {
    margin-top: auto;
  }
  &-nestedSelector {
    width: 100%;
    margin-top: auto;
    padding: 0.25rem;
  }
  @extend %modifierItem !optional;
}
