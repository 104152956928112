.loader {
  width: 100%;
  overflow: hidden;
}

.seamlessForm {
  height: 100%;
  &-fields {
    flex-grow: 1;
    margin: 1rem 1rem 8rem 1rem;
    padding: 0 1rem 0 1rem;

    &-item {
      margin: 1rem 0;
      height: 50px;
      border: 1px solid #ededed;
      background-size: 400% 400%;
      animation: gradient 15s ease infinite;
    }
  }

  &-footer-button {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  input {
    display: block;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-size: 1.25rem;
    }
  }
}
