@import "styles/config";
@import "client";

.loyaltyHeader {
  display: flex;
  padding: 1rem 1rem 0 1rem;

  // image flex-start alignment is needed to prevent image stretching on safari, if image is a .png
  &-image {
    width: 20%;
    align-self: flex-start;
  }
  &-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    &-text {
      text-align: center;
      @include media-breakpoint-down(md) {
        font-size: 1.25rem;
      }
    }
  }

  @extend %loyaltyHeader !optional;
}
