@import "styles/config";
@import "client";


$containerGutter: 1rem;

.complete {
  overflow-y: auto;

  &-content {
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: 2rem;
    margin-bottom: 3rem;
    
    &-information {
      width: 100%;
      height: 100%;
      justify-content: start;
    }

    &-img {
      max-width: 40%;
    }

    &-map {
      height: 15rem;
      width: 100%;
    }

    &-details {
      text-align: left;
      width: 100%;
    }

    &-items {
      padding: .625rem 0rem 0rem 0rem;
      margin: 0rem;
      list-style: none;
      text-decoration: none;
      clear: both;
    }

    &-timeContainer {
      padding: $containerGutter;
      &--top {
        display: flex;
      }
      &--info {
        flex-grow: 5;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      &--img {
        flex-grow: 0;
        display: flex;
        justify-content: flex-end;
      }

    }

    &-divider {
      height: .125rem
    }

    &-title {
      text-align: left;
      padding: $containerGutter;
    }
  }

  &-footer {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  @extend %complete !optional; 
}
