@import "../base.module.scss";

.empty {
  & img {
    margin-top: 0;
  }

  .message {
    width: 15rem;
    @include media-breakpoint-up(md) {
      width: 20rem;
    }
  }
}

.content {
  position: relative;
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: auto;
  text-align: center;
}
