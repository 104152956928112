.footer {
  width: inherit;
  display:inline-block;
  padding: 0.4rem;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  
  &-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      height: 1.1rem;
    }
  }
}