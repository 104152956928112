@import "styles/config";
@import "client";

$modifierItem-tab-background: transparent !default;

.itemDetails {
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  &-error {
    padding: 10px 5px;
    text-align: center;
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    &--info {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }
    &:last-child {
      flex-grow: 0;
    }
  }
  &-header {
    &--container {
      position: relative;
      background-color: white;
      div {
        border-top: none;
        border-left: none;
        border-right: none;
      }
    }
  }
  &-tabs {
    background: $modifierItem-tab-background;
    flex-grow: 1;
    @include no-scroll-bar;

    /* overflow-x: scroll; */
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: none;
    }
  }
  &-notes {
    padding: 20px;
  }
  &-back {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 1rem;
    left: 1rem;
    padding: 1rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 100%;
    @include elevation(2, 8, "hover");
    &:hover {
      cursor: pointer;
    }
    & div,
    & svg,
    & img {
      width: 1rem;
      height: 1rem;
    }

    & > div {
      width: 2.2rem;
      height: 2.2rem;
    }
    img {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
  &-image {
    padding-bottom: 56.25%;
    height: auto;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
  &-footer {
    -webkit-box-shadow: 0px -6px 44px -4px rgba(201, 199, 201, 0.54);
    -moz-box-shadow: 0px -6px 44px -4px rgba(201, 199, 201, 0.54);
    box-shadow: 0px -6px 44px -4px rgba(201, 199, 201, 0.54);
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  }
  @extend %itemDetails !optional;
}

.footerButtonContent {
  display: flex;
  flex-direction: column-reverse;
  span {
    display: block;
  }
}
.footerButtonText::after {
  content: "\A0";
  color: black;
}
/* Tablet 768px */

@media screen and (min-width: 48em) {
  .fixed {
    width: 80vw;
  }
}

@media screen and (min-width: 60em) {
  .fixed {
    width: 50vw;
  }
}

@media screen and (min-width: 72em) {
  .fixed {
    width: 40vw;
  }
}

@media screen and (min-width: 84em) {
}

@media screen and (min-width: 96em) {
}
