@import "styles/config";
@import "client";

.profile {
  margin-top: 2rem;
  padding-top: 4rem;

  &-header {
    margin-bottom: 1.875rem;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  &-section-container {
    margin-bottom: 1rem;
  }

  &-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 40.625rem;
    max-height: 40.625rem;
    position: relative;

    &-title {
      padding: 0.625rem;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;
      min-height: 0;

      &-cards {
        margin: 1rem 0 1rem 1rem;
        padding-right: 1rem;
      }
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
  }

  &-fields-container {
    flex-grow: 1;
    padding: 1rem 1rem;
    padding-bottom: 3.75rem;
    overflow-y: auto;
  }
  @extend %profile !optional;
}

.rewardText {
  background-color: #fbf9df;
  color: black;
  padding: 1.5rem;
  text-align: center;
  font-weight: 500;
}

.chooseLocationButton {
  color: white;
  padding: 1rem;
  font-weight: bold;
  margin: 0 auto;
  width: calc(100% - 2rem);
  border-radius: 0.2rem;
  text-align: center;
  cursor: pointer;
}
