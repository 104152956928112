@import "styles/config";
@import "client";

.guests {
  &-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem;
    @include media-breakpoint-up(md) {
      padding: 0 2rem 1rem 2rem;
    }
  }
  &-image {
    height: 100%;
    max-height: 4rem;
    margin: 0 auto;
  }
  &-primary,
  &-secondary {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &-primary {
    padding: 1rem 0 2rem 0;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    @include media-breakpoint-up(xs) {
      padding: 2rem 0 3rem 0;
    }
  }
  &-secondary {
    flex-grow: 1;
    padding: 2rem 0 0 0;
    @include media-breakpoint-up(xs) {
      padding: 3rem 0 0 0;
    }
  }
  &-header {
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(xs) {
      margin-bottom: 1rem;
    }
  }
  &-title {
    margin-bottom: 1rem;
    @include media-breakpoint-up(xs) {
      margin-bottom: 2rem;
    }
    &--secondary {
      text-align: left;
      margin: 1rem 0;
    }
  }
  &-button {
    min-height: 3rem;
    box-shadow: 0px 0.25rem 0.5rem #d8d8d8;
    border-radius: 0.25rem;
  }
  &-field {
    position: relative;
    text-align: left;
    & > div {
      margin-bottom: 0 !important;
    }
    &--input {
      min-height: 3rem;
      padding: 1rem;
      background: #f5f5f5;
      border-radius: 0.25rem;
      border: none;
    }
    &--button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      &,
      & img {
        max-height: 2rem;
      }
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%) scaleX(-1);
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-legal {
    margin: auto 0 0.5rem 0;
    padding-top: 0.5rem;
    @include media-breakpoint-up(xs) {
      margin: auto 0 1rem 0;
      padding-top: 1rem;
    }
  }
  @extend %guests !optional;
}
