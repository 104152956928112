@import "styles/config";
@import "client";

.modifierItem {
  margin-bottom: 0.5rem !important;
  padding: 0.5rem;
  min-height: 6rem;
  @include media-breakpoint-up(sm) {
    margin-bottom: 1rem !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  &:hover {
    cursor: pointer;
  }
  &-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    border: none !important;
    @include elevation(1);
    @include media-breakpoint-up(sm) {
      min-height: 5.5rem;
    }
  }

  &,
  &-row,
  &-col,
  &-content {
    display: flex;
    justify-content: center;
  }
  &-btn span {
    display: flex;
    justify-content: center;
  }
  &-row {
    height: 100%;
    flex-direction: row;
  }

  &-action {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1rem;
  }
  &-col {
    overflow: hidden;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    will-change: width;
    transition: width 0.15s ease-in-out;
    &.decrement {
      background: #db2828;
      &:hover {
        background: #d01919;
      }
      &:focus {
        background: #ca1010;
      }
      &:active {
        background: #b21e1e;
      }
    }
    &.increment {
      background: #21ba45;
      &:hover {
        background: #16ab39;
      }
      &:focus {
        background: #0ea432;
      }
      &:active {
        background: #198f35;
      }
    }
    & button {
      width: 100% !important;
      height: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
      background: transparent !important;
    }
    &--active {
      height: 100%;
      flex: 1;
      & button {
        padding: 0 0.5rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        & span {
          display: inline-block;
          font-family: sans-serif;
          color: white !important;
        }
      }
    }

    &--inactive {
      width: 0;
    }
  }
  &-content {
    flex-grow: 1;
    flex-direction: column;
    padding: 0.5rem 0.5rem 0 0.5rem;
    &--name {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--name:not(:only-child) {
      margin-bottom: 0.5rem;
    }
    &--price {
      padding: 0 0 0.5rem 0.5rem;
    }
  }

  @extend %modifierItem !optional;
}
