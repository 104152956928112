@import "styles/config";
@import "client";

$pillColor: #ed9a00 !default;
$pillCaret: #ed9a00 !default;
$pillBackground: #f5f5f5 !default;

.pillButtonWrapper {
  background: none;
  outline: none;
  border: none;

  @include media-breakpoint-down(xs) {
    background: $pillBackground;
  }
}
.pillContainer {
  align-items: center;
  width: auto;
  height: 3rem;
  display: flex;
  margin: auto;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-radius: 100px;
  background: $pillBackground;

  span {
    text-align: left;
  }

  @include media-breakpoint-down(xs) {
    background: $pillBackground;
    margin-top: 1.5rem;
    border-radius: 0;
    width: 100%;
  }

  &--pillCaret {
    display: inline;
    margin: 0 0.5rem;
    & path {
      fill: $pillCaret;
    }
    cursor: pointer;
  }

  &--pillDeliver {
    display: inline;
    margin: 0 0.5rem;
    & path {
      fill: $pillCaret;
    }
  }

  @extend %pillContainer !optional;
}

.pillText {
  font-size: 1rem;
  font-family: inherit;
  text-align: left;

  @include media-breakpoint-down(xs) {
    font-size: 0.75rem;
  }

  &-highlight {
    color: $pillColor;
    font-weight: 700;
    font-size: 1rem;
    @include media-breakpoint-down(xs) {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.2rem;
    }
  }
}

@import "styles/theme.module.scss";
