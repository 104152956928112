.quantity {
  border-bottom: none !important;
  border-left: none !important;
  align-self: center;
}

.add-to-cart {
  border: none !important;
}

.button {
  text-align: center;
}
