@import "styles/config";
@import "client";

.packingItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 1.25px;

  &-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }

  &-name {
    flex-grow: 1;
  }

  &-price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.75rem 0px;
    flex-grow: 1;
    vertical-align: middle;

    &-content {
      margin-right: 0.75rem;
    }
  }

  @extend %packingItem !optional;
}
