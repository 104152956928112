@import "styles/config";
@import "client";

.invalidItem {
  padding: 0.5rem;
  border: 1px solid $pink;
  li {
    margin-bottom: 0;
    span {
      color: $red;
    }
    button {
      span {
        color: $red !important;
      }
    }
  }
  margin-bottom: 1rem;
}
