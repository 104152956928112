@import "styles/config";
@import "client";

.fields {
  &-title {
    margin: 2rem 1rem 1rem 1rem;
    text-align: center;
  }
  &-container {
    margin: 1.6rem 1.6rem 0 1.6rem;
    overflow: auto;
  }
  &-input {
    margin: 1rem 0 1rem 0;
    border: 1px solid $gray-300;
    border-radius: 0.25rem;
    padding: 1rem 3rem;

    &::placeholder {
      color: $gray-300;
    }
  }
  &-button {
    margin: 0 1.6rem;
    padding: 1rem 2.5rem;
  }
  &-error {
    margin-top: -1rem;
    margin-bottom: 1rem;
    display: inline-block;
  }
}

.link {
  margin-top: 1rem;
  text-align: center;

  & a {
    border-bottom: 1px solid;
  }
}

.discount-form {
  display: flex;
}

.discount .input {
  flex-grow: 1;
}

.discount .apply {
  flex-grow: 1;
}

.error {
  text-align: center;
}
