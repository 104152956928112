.input {
  width: 100%;
  padding-right: 30px;
  letter-spacing: 0;
  text-decoration: none;
  border: none;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background: transparent;
  padding: 10px 0px;

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  &[type="password"] {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", "Noto Color Emoji";
  }

  &-container {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.875rem;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: start;
  }

  &-suffix {
    position: absolute;
    top: 55%;
    right: 0.75rem;
    z-index: 2;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    & > img,
    & > i {
      height: 100%;
    }
  }
}
@import "styles/theme.module.scss";
