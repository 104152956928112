@import "styles/config";
@import "client";

.placementModifierItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 2.125rem;
  height: 2.125rem;
  margin-right: 0.75rem;
  opacity: 0.5;
  &-active {
    opacity: 1;
  }
  span {
    height: 1.8rem;
    width: 2rem;
  }

  &:last-child {
    margin-right: 0.5rem;
  }

  @include media-breakpoint-down(xs) {
    margin-right: 0.5rem;
    width: 1.75rem;
    height: 1.75rem;

    span {
      height: 1.5rem;
      width: 1.7rem;
      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}
