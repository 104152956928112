@import "styles/config";
@import "client";

.orderField {
  border-bottom: 1px solid gray;

  &-label {
    margin-bottom: 10px;
    flex-grow: 1;
  }

  &-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    & > * {
      display: inline-block;
    }

    & > .field.disabled {
      opacity: 0.5;
    }
  }
  @extend %orderField !optional;
}
