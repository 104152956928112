@charset "UTF-8";
@import "styles/config";
@import "client";

$enableDarkSelect: false !default;
.select-container {
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.select {
  width: 100%;
  font-size: inherit;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 1px;
  text-indent: 0.01px;
  text-overflow: "";
  border-radius: 0px;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-decoration: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  // these things must be defined in client scss if client theme calls for dark color
  @if $enableDarkSelect {
    @include dark-select($darkSelectColor);
  }

  option {
    @if $enableDarkSelect {
      @include dark-select($darkSelectColor);
    }
  }
}

/*Hiding the select arrow for IE10*/
.select::-ms-expand {
  display: none;
}

.select-container::before,
.select-container::after {
  content: "";
  position: absolute;
  pointer-events: none;
}

// prevents unicode characters from being compiled before CSS file is made. We want our CSS output to compile to content:"\25BC", NOT content:"▼"
@function unicode($str) {
  @return unquote('"') + unquote(str-insert($str, "\\", 1)) + unquote('"');
}

.select-container::after {
  content: unicode("25BC");
  height: 1em;
  font-size: 0.625em;
  line-height: 1;
  right: 1.2em;
  top: 50%;
  margin-top: -0.5em;
}

.select-container::before {
  /*  Custom dropdown arrow cover */
  width: 2em;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 3px 3px 0;
}

.select-container::after {
  color: rgba(0, 0, 0, 0.6);
}

.select-container .select[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
}
.select {
  width: 100%;
  letter-spacing: 0;
  text-decoration: none;
}
.select:focus {
  outline: none !important;
  box-shadow: none;
}
