@import "client";

.field {
	margin-top: 0.938rem;
}

@keyframes blink {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.blink {
	animation: blink 1s infinite;
}

.geo-icon {
	height: auto !important;
	cursor: pointer;
	width: 1.125rem;
}
