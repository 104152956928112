@import "styles/config";
@import "client";

.tipsContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;

  @include media-breakpoint-down(sm) {
    margin-top: 0.125rem;
  }

  &-wrapper {
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin: 0.625rem 0;
    padding: 1rem 1rem 0rem 0rem;

    @include media-breakpoint-down(sm) {
      flex-direction: row;
      width: 100%;
      padding: 0.05rem;
    }
    @media only screen and (max-width: 69.5rem) and (min-width: 64rem) {
      width: 80%;
    }

    &--singleTip {
      box-sizing: border-box;
      border-radius: 0.25rem;
      display: block;
      margin: 1em auto;
      text-align: center;
      -moz-padding-end: 0.125rem; // ONLY FOR FIREFOX
      &:first-child {
        margin-left: -0.08rem;
      }
      &:last-child {
        -moz-padding-end: 0.5rem;
      }
    }

    &--legend {
      display: flex;
      padding: 0 0.625rem;
      align-items: center;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 0.625rem;
      line-height: 0.75rem;
      color: $black;
    }

    &--customAmountSelected {
      font-style: inherit;
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem 0.25em;
      border-radius: 0.313rem;
    }

    &--amount {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      font-weight: bold;
      text-align: center;
      line-height: 0.829rem;
      height: 1.5rem;
      margin: 0.125rem;
      border: none;
      background: none;
      cursor: pointer;
      width: 3rem;
      -moz-padding-start: 0rem;

      @include media-breakpoint-down(xs) {
        margin: 0.188rem 0rem;
        padding: 0 0.188rem;
      }
    }

    &--tipcontent {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 12.5rem;
      align-items: center;
      margin: 0 auto;
      justify-content: space-evenly;
    }

    &--saveButton {
      width: 100%;
      height: 30%;
    }

    &--customInputWrapper {
      margin: 0.625rem auto;
      width: 25%;

      @include media-breakpoint-down(xs) {
        width: 40%;
      }
      @media only screen and (max-width: 25.875em) and (min-width: 23.5em) {
        width: 29%;
      }

      @media only screen and (max-width: 37.5em) and (min-width: 25.93em) {
        width: 20%;
      }

      @include media-breakpoint-up(lg) {
        width: 15%;
      }
    }

    &--customInput {
      align-items: center;
      margin: 0 auto;
      font-size: 2.4rem;
      font-weight: bolder;

      width: 100%;
      margin: 0 auto;
      display: flex;

      input {
        padding: 0rem 0.938rem;
        outline: 0;
        border: none;
        min-width: 9rem;
        max-width: 12.5rem;
        margin-left: -0.813rem;
        font-weight: bold;

        &[type="number"] {
          -moz-appearance: textfield; // ONLY FOR FIREFOX
        }
      }

      label {
        position: relative;
        @include media-breakpoint-down(sm) {
          left: 0.125rem;
        }

        @include media-breakpoint-up(sm) {
          left: 0.25rem;
        }
      }
    }
    &--customError {
      margin: 0;
      color: red;
    }
  }

  @extend %tipsContainer !optional;
}

@import "styles/theme.module.scss";
