@import "styles/config";
@import "../modifierItem-grid.scss";

.modifierItem {
  min-height: 7.5rem !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 10px 10px transparent;
    background-color: transparent;
  }
  &-image {
    max-width: 6.25rem;
    max-height: 6.25rem;
  }
  &-content {
    display: flex;
    flex-direction: column;
    &--name {
      max-width: 80%;
    }
    &--desc {
      order: 1;
      width: 100%;
      margin-bottom: 0;
    }
    &--price {
      position: absolute;
      right: 0;
      margin-right: 6px;
    }
  }
  &-selectors {
    justify-content: flex-end;
    padding: 0.25rem;
    &--qty {
      margin: 0 !important;
    }
    &--qty,
    &--btn {
      width: 2rem !important;
      height: 2rem !important;
    }
  }
}
