@import "styles/config";
@import "client";

.restaurantsFilter {
  margin: 0rem 1.5rem 1.5rem 1.5rem;
  cursor: pointer;
  position: relative;

  @include media-breakpoint-down(sm) {
    margin: 0rem 1rem 1rem 1rem;
  }

  &-container {
    @include no-scroll-bar;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-title {
    margin-bottom: 1rem;
    display: inline-block;
  }

  &-card {
    min-width: 8.75rem;
    flex-basis: 8.75rem;
    margin-right: 1rem;
    filter: brightness(0.6);
    &-selected {
      filter: brightness(1);
    }

    &-name,
    &-desc {
      display: inline-block;
      margin: 0.5rem 0rem;
    }
    &-image {
      height: 5rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  @extend %restaurantsFilter !optional;
}
