@import "client";

.cardsOnFile {
  height: 100%;

  &-content {
    padding: 15px;
  }

  &-item {
    padding: 7.5px 0px;
    border: none;
    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      padding-bottom: 0px;
    }
  }
}

.navigation {
  padding: 15px;
}

.makePrimary {
  white-space: nowrap;
}
