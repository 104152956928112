@import "styles/config";
@import "client";

.input-container {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.875rem;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}

.input-container .input-suffix {
  position: absolute;
  top: 40%;
  z-index: 2;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-suffix {
  right: 0px;
  height: 100%;
}
.numeric .input-suffix {
  left: 0px;
  height: 0%;
}

.input-suffix > img,
.input-suffix > i {
  height: 100%;
}

.input-container .input:disabled,
.input-container .input:disabled::placeholder,
.input-container .input:disabled::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.input {
  width: 100%;
  padding-right: 30px;
  letter-spacing: 0;
  text-decoration: none;
  border: none;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background: transparent;
}

.input:focus {
  outline: none !important;
  box-shadow: none;
}

.numeric .input {
  padding-right: 0px;
  text-align: right;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
