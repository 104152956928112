@import "styles/config";
@import "client";

$modifierItem-selectors--nested-color: #717171;

.modifierItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 1rem 0;
  @include media-breakpoint-up(md) {
    padding: 2rem;
  }
  border-bottom-width: 2px;
  border-bottom-style: solid;
  &,
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }

  &[data-nested="true"] {
    align-items: flex-start;
  }
  
  &-selectors {
    &--nested {
      color: $modifierItem-selectors--nested-color;
      font-size: 1.75rem;
      line-height: 1rem;
    }
  }
  &-nestedItems {
    list-style: none;
    padding-inline-start: 0;
    & li {
      padding: 0 1rem 0 1rem;
    }
  }
  &-name {
    margin-right: 1rem;
  }
  &-price {
    margin-left: auto;
    margin-right: 1rem;
  }

  &-qty {
    display: flex;
    align-items: center;
    justify-content: center;
    &--text {
      margin: 0 0.5rem;
    }
    &--minus,
    &--plus {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #000;
      border-radius: 100%;
    }
    &--minus span:before {
      content: "-";
    }
    &--plus span:before {
      content: "+";
    }
  }
  &-radio {
    border-radius: 50%;
    border: 0.25rem solid #fff;
    & label {
      width: 1rem;
      height: 1rem;
      @include media-breakpoint-up(md) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  @extend %modifierItem !optional;
}
