@import "styles/config";
@import "client";

.option {
  &-content {
    border-right-width: 0px;
    border-left-width: 0px;
    border-top-width: 0px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  &-line {
    text-align: left;
    padding: 5px 0;
    margin: 0 auto;
  }

  &-name {
    padding-left: 9px;
    white-space: pre-wrap;
  }
  &-lineText {
    position: relative;
    padding: 20px 0px;
    display: inline-block;
  }

  &-error {
    z-index: 9;
  }

  @extend %option !optional;
}
