@import "styles/config";
@import "client";

.menuDiscovery {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 10vh;
  width: 94%;
  margin: 10rem auto;

  @include media-breakpoint-up(sm) {
    margin-top: 5rem;
  }

  @media only screen and (max-width: 375px) {
    width: 91%;
  }

  &-hero {
    &--title {
      font-size: 1.25rem;
      line-height: 1.644rem;
      display: block;
      margin: 1rem 0;

      @include media-breakpoint-up(sm) {
        font-size: 1.875rem;
        line-height: 2.466rem;
      }
    }

    &--description {
      width: 95%;
      display: block;

      @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
        width: 100%;
        line-height: 0.875rem;
      }

      @media only screen and (max-width: 23.43em) {
        width: 100%;
      }
    }
  }

  &-heroImage {
    position: relative;
    margin-top: 0.875rem;

    img {
      width: 100%;
      height: 9.625rem;
      object-fit: cover;
      border-radius: 0.125rem;

      @include media-breakpoint-up(sm) {
        height: 21.875rem;
      }
    }
    @include media-breakpoint-up(sm) {
      margin-top: 1.25rem;
    }

    &--textContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      height: 1.875rem;
      left: 0;
      top: 7.8rem;
      right: 0;
      opacity: 0.7;
      background-color: rgba(250, 250, 250, 0.966);

      @include media-breakpoint-up(sm) {
        height: 3.3rem;
        top: 18.6rem;
      }
      @media only screen and (max-width: 375px) {
        top: 7.8rem;
      }

      &--RgName {
        font-size: 1.125rem;
        padding-left: 10px;
        font-weight: bold;
        line-height: 19.17px;
        @include media-breakpoint-up(sm) {
          font-size: 24px;
          line-height: 25.56px;
        }
      }

      &--cuisine {
        font-size: 1rem;
        line-height: 1.125rem;
        padding-right: 10px;
        font-style: normal;
        font-weight: normal;
        @include media-breakpoint-up(sm) {
          font-size: 1.25rem;
        }
      }
    }
  }

  &-explore {
    margin-top: 4.313rem;
    @include media-breakpoint-up(sm) {
      margin-top: 6.75rem;
    }

    &--textContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.75rem;

      @include media-breakpoint-up(sm) {
        margin-bottom: 1.849rem;
      }

      &--title {
        font-weight: 700;
        font-style: normal;
        font-size: 1.25rem;
        line-height: 1.644rem;

        @include media-breakpoint-up(sm) {
          font-size: 1.875rem;
          line-height: 2.466rem;
        }
      }

      &--seeAll {
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
        line-height: 1.315rem;
        color: #2542fb;
        cursor: pointer;

        @include media-breakpoint-up(sm) {
          font-size: 1.125rem;
          line-height: 1.479rem;
        }
      }
    }

    &--RgListContainer {
      margin-bottom: 4.438rem;

      &--singleRgContainer {
        display: flex;
        overflow: auto;
        display: flex;
        height: 156px;
        position: relative;
        flex-direction: row;
        justify-content: space-between;

        &::-webkit-scrollbar {
          display: none;
        }

        &--singleRg {
          height: 7.575rem;
          width: 5.884rem;
          margin: 0 1.25rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          @include media-breakpoint-up(sm) {
            margin: 0 3.125rem;
          }

          img {
            width: 5.884rem;
            cursor: pointer;
            height: 5.884rem;
            border-radius: 50%;
            box-shadow: 0rem 0.063rem 0.313rem rgba(0, 0, 0, 0.15);
          }

          &--name {
            text-align: center;
            font-size: 0.875rem;
            line-height: 1.151rem;
            font-weight: 400;
            font-style: normal;
            white-space: nowrap;
            width: 4.938rem;
            overflow: hidden;
            text-overflow: ellipsis;
            @include media-breakpoint-up(sm) {
              width: 5.188rem;
            }
          }
        }
      }
    }
  }

  &-drawer {
    background: red;
  }
  @extend %menuDiscovery !optional;
}
