@import "client";

.loyaltyRewardSection {
  padding: 1.25rem 1rem;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  overflow-x: scroll;
  @extend %loyaltyRewardSection !optional;
}

.redeemPromoButton {
  float: right;
}

.horizontalScrollbar {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.horizontalScrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: none;
}

.rewardCard {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-right: 2rem;
}
