@import "styles/config";
@import "client";

.toppingAmountModifierItem {
  padding: 0.6rem 0.75rem 0.4rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: 0.5rem;

  @include media-breakpoint-down(xs) {
    padding: 0.6rem 0.5rem 0.4rem 0.5rem;
  }
}
