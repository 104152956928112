@import "styles/config";
@import "client";

.loyalty {
  &-back {
    display: flex;
    flex-direction: row;
    height: 3.5rem;
    padding: 1rem;
  }
  &-dialog {
    padding: 1rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50vw;
    @include media-breakpoint-down(xs) {
      width: 80vw;
    }

    p {
      text-align: center;
      margin: 2rem;
      line-height: 1.5;
      @include media-breakpoint-down(xs) {
        margin: 1rem;
      }
    }

    &-actions {
      display: flex;
      justify-content: space-between;

      button {
        width: 48%;
        padding: 0.5rem 1rem;
      }
    }
  }
  &-banner {
    display: flex;
    justify-content: center;

    span {
      display: inline-block;
      width: 80%;
      text-align: center;
      padding: 0.5rem;
      margin: 0 auto;
    }
  }

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem auto;

    &-icon {
    }
    &-text {
      margin-left: 1.75rem;
      span {
        display: block;
      }
    }
  }
  &-body {
    overflow-y: scroll;
    &-section {
      display: flex;
      flex-direction: column;
      margin: 1rem;
      padding-top: 0.5rem;

      h2 {
        margin-bottom: 0;
      }
      &-items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 0.75rem;
        align-items: stretch;

        &-disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: not-allowed;
        }

        @include media-breakpoint-down(xs) {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;
          flex-direction: column;
        }
      }
    }
  }

  &-selectLocationButton {
    width: calc(100% - 2rem);
    padding: 0.5rem;
    margin: 1rem;
  }
}

.rewardItem {
  position: relative;
  justify-self: stretch;

  @include media-breakpoint-down(xs) {
    width: 100%;
    min-height: 10rem;
  }

  &-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-down(xs) {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1rem;
      padding: 1rem;
      border: 1px solid #c8ccd0;
      border-radius: 0.25rem;
    }
  }

  &-unavailable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &-remove {
    z-index: 99;
    background: none;
    border: none;
    position: absolute;
    top: 0.5rem;
    right: 0;
    cursor: pointer;
  }

  &-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(xs) {
      justify-content: space-between;
      height: 7rem;
      align-items: flex-start;
    }
  }

  &-image,
  &-name {
    margin-bottom: 0.5rem;
    text-align: center;
    @include media-breakpoint-down(xs) {
      text-align: left;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    min-height: 7rem;
    @include media-breakpoint-down(xs) {
      width: 7rem;
      height: 7rem;
      margin-bottom: 0;
    }
  }

  &-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.divider {
  width: 100%;
  margin: 0 auto;
  margin: 0.5rem 0 1.5rem 0;
}
