@import "styles/config";
@import "client";

.itemDetailFooter {
  position: relative;
  &-quantity {
    position: absolute;
    bottom: 20%;
    left: 0.5rem;
    z-index: 1;
    @include media-breakpoint-up(sm) {
      left: 2rem;
    }
    @include media-breakpoint-up(md) {
      left: 3rem;
    }
  }

  &-button {
    height: 100%;
    width: 100%;
    span {
      display: inline-block;
    }
  }
}
