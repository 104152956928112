.wrapper {
  display: inline;
  position: relative;
  cursor: pointer;
  vertical-align: top;
  user-select: none;
}

.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

@import "styles/theme.module.scss";