@import "client";

.deliveryForm {
  padding-top: 1rem;
}

.button {
  padding: 15px;
}

.disabled {
  opacity: 0.75;
}
