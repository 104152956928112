@import "styles/config";
@import "client";

.packingItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  border-right: 0px !important;
  border-left: 0px !important;
  min-height: 4rem;

  &:not(first-child){
    border-top: 0px !important;
  }

  &-name {
    flex-grow: 1;
  }

  &-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 3;
    cursor: pointer;
  }

  &-checkmark {
    position: absolute;
    z-index: 2;
    right: 1.75rem;
    top: 1.25rem;
  }

  &-price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0px;
    flex-grow: 1;
    vertical-align: middle;
    height: 3rem;
    &-value {
      margin-right: 10px;
    }
    & > span > label {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  @extend %packingItem !optional;
}
