@import "styles/config";
@import "client";

$unique-input-border-color: false !default;
@if $unique-input-border-color {
  .fieldItem {
    input,
    select {
      border-color: $unique-input-border-color;
    }
  }
}

.fieldItem {
  @extend %fieldItem !optional;

  margin-bottom: 1rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
  }
  label {
    display: block;
    @include media-breakpoint-up(md) {
      margin-bottom: .1rem;
    }
  }
}
