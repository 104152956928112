
.bank-rewards-info-section {
    padding: 1.25rem 1rem;
    padding-bottom: 6rem;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    height: 100%;
    @media screen and (max-width: 24em) {
        height: 84%;
    }
}
.bank-rewards-top {
    display: flex;
}
.container {
    display: flex;
    padding: 1rem 1rem 0rem 1rem;
}
.bank-rewards-back {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%; 
    margin-top: 1rem;
    margin-right: 1rem;
    & img {
        margin-right: 0;
    }
}

.bankCardInfo {
    &--title {
        margin-bottom: 0.5rem;
    }
    &--divider {
        margin: 1rem 0;
        border-bottom: 1px solid black;
        width: 100%;
    }
    &--details {
       padding: 1rem;
       height: 73%;
    }
}


.drawer {
    &--container {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    &--dialogue {
        text-align: center;
        margin-bottom: 20px;
        padding: 0px 4rem;
        @media screen and (max-width: 24em) {
            padding: 0;
        }
    }
    &--button {
        padding: 10px 0px;
    }
}