@import "./itemDetails.module.scss";

%itemDetails {
  .itemDetails-back {
    width: 2rem;
    height: 2rem;
    padding: 0;
    background: transparent;
    box-shadow: none;
    & div,
    & div img {
      width: 100%;
      height: 100%;
    }
  }
}
