@import "styles/config";
@import "client";

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  .fields-container {
    background-color: $white;
    flex-grow: 1;
    padding: 15px 15px;
    @extend %fieldsContainer !optional;
  }

  .social-btns {
    margin: 3rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // Todo remove this extend for socialButtons
    @extend %socialButtons !optional;
  }

  .lineBreak {
    color: $black;
    display: flex;
    flex-direction: row;

    &:before {
      content: "";
      flex: 1 1;
      border-bottom: 2px solid $black;
      margin: auto 0.5rem auto 0;
    }
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 2px solid $black;
      margin: auto 0 auto 0.5rem;
    }
  }

  .terms {
    text-align: center;
    padding-bottom: 1.5rem;
  }

  .password-wrapper {
    position: relative;
    svg {
      height: 1.1rem;
      width: 1rem;
    }
    .eye {
      position: absolute;
      top: 2.5rem;
      right: 2%;
      cursor: pointer;
      box-shadow: none;
    }
  }

  .footer {
    &-logo {
      height: 2.5rem;
      margin-bottom: 1rem;
    }

    &-signUp {
      padding: 0.75rem 1rem;
      display: flex;
      justify-content: center;
    }
  }
  @extend %templateForm !optional;
}
