@import "styles/config";
@import "client";

.giftCardSuccess {
  background: $background;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: calc(50% - 3rem);
  -webkit-transform: translate(-50%, calc(-50% - 3rem));
  transform: translate(-50%, calc(-50% - 3rem));
  padding: 2rem;
  z-index: 100000;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  flex-direction: column;
}

.successText {
  font-weight: 200;
  padding-bottom: 1rem;
}

.giftCardButtonDiv {
  display: flex;
  flex-direction: row-reverse;
}

.giftCardSuccessButton {
  font-weight: bold;
}
