@import "styles/config";
@import "client";

.background {
  padding: 0.75rem 0;
}

.confirm {
  width: 100%;
  text-align: center;
  @extend %confirm !optional;
}

.dialogue {
  margin-bottom: 15px;
}
