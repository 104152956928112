:root {
  --focus-text-color: #E37F68;
  --focus-ring-color: #2274AF;
}.base {
  
  
  background-color: #FFFFFF;
}
.accentDark {
  
  
  background-color: #E37F68;
}
.baseBlack {
  
  
  background-color: #212121;
}
.alternateGray {
  
  
  background-color: #EBE6D2;
}
.alternateGrayBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #E37F68;
  background-color: #EBE6D2;
}
.accentLight {
  
  
  background-color: #2274AF;
}
.baseBackgroundGray {
  
  
  background-color: #F0F0F0;
}
.baseGrayBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #505962;
  background-color: #FFFFFF;
}
.backgroundGrayBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #88919A;
  background-color: #EBE6D2;
}
.accentDarkHeavyBordered {
  
  
  border-width: 3px;
  border-style: solid;
  border-color: #E37F68;
}
.baseGrayBorderedHeavy {
  
  
  border-width: 3px;
  border-style: solid;
  border-color: #505962;
  background-color: #FFFFFF;
}
.roundedBaseClear {
  &:active {
    border-color: #E37F68;
  }
  &:disabled {
    border-color: #E37F68;
  }
  border-radius: 4px;
  border-width: 4px;
  border-style: solid;
  border-color: #E37F68;
}
.roundedBase {
  
  
  border-radius: 2px;
  border-width: 4px;
  border-style: solid;
  border-color: #505962;
  background-color: #FFFFFF;
}
.roundedBaseBlack {
  &:active {
    border-color: #212121;
  }
  &:disabled {
    border-color: #EBE6D2;
  }
  border-radius: 5px;
  border-width: 5px;
  border-style: solid;
  border-color: #212121;
  background-color: #212121;
}
.roundedBaseGray {
  &:active {
    border-color: #505962;
  }
  
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #505962;
  background-color: #FFFFFF;
}
.roundedAlternateGray {
  
  
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #505962;
}
.roundedLightGray {
  
  
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #88919A;
}
.roundedBlackClearBackground {
  
  &:disabled {
    border-color: #505962;
  }
  border-radius: 3px;
  border-width: 3px;
  border-style: solid;
  border-color: #212121;
}
.roundedWarningClearBackground {
  
  &:disabled {
    border-color: #EBE6D2;
  }
  border-radius: 3px;
  border-width: 3px;
  border-style: solid;
  border-color: #FF2A00;
}
.roundedAccentDark {
  
  
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #E37F68;
  background-color: #E37F68;
}
.roundedAccentClearBackground {
  
  
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #E37F68;
}
.circleGray {
  
  
  border-radius: 25px;
  border-width: 1px;
  border-style: solid;
  border-color: #505962;
  background-color: #EBE6D2;
}
.circleAccentLight {
  
  
  border-radius: 100px;
  background-color: #2274AF;
}
.circleToggleStandard {
  &:active {
    border-color: #E37F68;
  }
  
  border-radius: 100px;
  border-width: 3px;
  border-style: solid;
  border-color: #E37F68;
}
.clearBaseGrayBordered {
  
  
  border-radius: 5px;
}
.circleAccentDark {
  &:active {
    border-color: #E37F68;
  }
  
  border-radius: 100px;
  border-width: 3px;
  border-style: solid;
  border-color: #E37F68;
  background-color: #E37F68;
}.title1 {font-family: "EVA-CUTE";
  font-size: 2rem;
  font-weight: 400;}
.title2 {font-family: "EVA-CUTE";
  font-size: 1.625rem;
  font-weight: 400;}
.title3 {font-family: "FivoSansModern-Heavy";
  font-size: 1.25rem;
  font-weight: 700;}
.title4 {font-family: "FivoSansModern-Heavy";
  font-size: 1rem;
  font-weight: 700;}
.title5 {font-family: "FivoSansModern-Heavy";
  font-size: 2rem;
  font-weight: 400;}
.body1 {font-family: "FivoSansModern-Bold";
  font-size: 1rem;
  font-weight: 700;}
.body2 {font-family: "FivoSansModern-Medium";
  font-size: 1rem;
  font-weight: 400;}
.body3 {font-family: "FivoSansModern-Medium";
  font-size: 0.875rem;
  font-weight: 400;}
.body4 {font-family: "FivoSansModern-Medium";
  font-size: 0.75rem;
  font-weight: 600;}.title1_baseBlack {@extend .title1;  color: #212121;
  text-transform: uppercase;}
.title1_baseWhite {@extend .title1;  color: #FFFFFF;
  text-transform: uppercase;}
.title1_accentDark {@extend .title1;  color: #E37F68;
  text-transform: uppercase;}
.title1_baseWarning {@extend .title1;  color: #FF2A00;
  text-transform: uppercase;}
.title2_baseWhite {@extend .title2;  color: #FFFFFF;
  text-transform: uppercase;}
.title2_accentDark {@extend .title2;  color: #E37F68;
  text-transform: uppercase;}
.title2_baseWarning {@extend .title2;  color: #FF2A00;
  text-transform: uppercase;}
.title2_baseBlack {@extend .title2;  color: #212121;
  text-transform: initial;}
.title2_baseGray {@extend .title2;  color: #505962;
  text-transform: initial;}
.title2_accentLight {@extend .title2;  color: #2274AF;
  text-transform: initial;}
.title3_baseWhite {@extend .title3;  color: #FFFFFF;
  text-transform: initial;}
.title3_baseWhiteAlt {@extend .title3;  color: #FFFFFF;
  text-transform: initial;}
.title3_alternateGray {@extend .title3;  color: #EBE6D2;
  text-transform: initial;}
.title3_accentDark {@extend .title3;  color: #E37F68;
  text-transform: initial;}
.title3_accentLight {@extend .title3;  color: #2274AF;
  text-transform: initial;}
.title3_baseBlack {@extend .title3;  color: #212121;
  text-transform: initial;}
.title3_baseGray {@extend .title3;  color: #505962;
  text-transform: initial;}
.title4_baseGray {@extend .title4;  color: #505962;
  text-transform: initial;}
.title4_accentDark {@extend .title4;  color: #E37F68;
  text-transform: initial;}
.title4_baseWarning {@extend .title4;  color: #FF2A00;
  text-transform: initial;}
.title4_accentLight {@extend .title4;  color: #2274AF;
  text-transform: initial;}
.title4_baseBlack {@extend .title4;  color: #212121;
  text-transform: initial;}
.title4_baseWhite {@extend .title4;  color: #FFFFFF;
  text-transform: initial;}
.title4_baseWhiteUps {@extend .title4;  color: #FFFFFF;
  text-transform: uppercase;}
.title4_alternateGray {@extend .title4;  color: #EBE6D2;
  text-transform: initial;}
.title5_baseBlack {@extend .title5;  color: #212121;
  text-transform: initial;}
.body1_baseBlack {@extend .body1;  color: #212121;
  text-transform: initial;}
.body1_baseGray {@extend .body1;  color: #505962;
  text-transform: initial;}
.body1_baseGray_cap {@extend .body1;  color: #505962;
  text-transform: initial;}
.body1_baseBlack_cap {@extend .body1;  color: #212121;
  text-transform: initial;}
.body1_baseWhite {@extend .body1;  color: #FFFFFF;
  text-transform: initial;}
.body1_accentDark {@extend .body1;  color: #E37F68;
  text-transform: initial;}
.body1_accentLight {@extend .body1;  color: #2274AF;
  text-transform: initial;}
.body1_baseWarning {@extend .body1;  color: #FF2A00;
  text-transform: initial;}
.body1_alternateGray {@extend .body1;  color: #EBE6D2;
  text-transform: initial;}
.body1_baseLink {@extend .body1;  color: #4A90E2;
  text-transform: initial;}
.body2_accentLight {@extend .body2;  color: #2274AF;
  text-transform: initial;}
.body2_baseGray {@extend .body2;  color: #505962;
  text-transform: initial;}
.body2_alternateGray {@extend .body2;  color: #EBE6D2;
  text-transform: initial;}
.body2_baseBlack {@extend .body2;  color: #212121;
  text-transform: initial;}
.body2_accentDark {@extend .body2;  color: #E37F68;
  text-transform: initial;}
.body2_baseWarning {@extend .body2;  color: #FF2A00;
  text-transform: initial;}
.body3_baseWhite {@extend .body3;  color: #FFFFFF;
  text-transform: initial;}
.body3_baseBlack {@extend .body3;  color: #212121;
  text-transform: initial;}
.body3_baseGray {@extend .body3;  color: #505962;
  text-transform: initial;}
.body3_accentLight {@extend .body3;  color: #2274AF;
  text-transform: initial;}
.body3_alternateGray {@extend .body3;  color: #EBE6D2;
  text-transform: initial;}
.body4_accentLight {@extend .body4;  color: #2274AF;
  text-transform: uppercase;}
.body4_baseBlack {@extend .body4;  color: #212121;
  text-transform: uppercase;}
.body4_accentBrown {@extend .body4;  color: #B53317;
  text-transform: uppercase;}.buttonPrimary {
  &:active {
    @extend .body1_accentDark;
  }
  &:disabled {
    @extend .title1_accentDark;
  }
  @extend .body1_baseGray;
}
.buttonPrimaryStandard {
  &:active {
    @extend .title4_alternateGray;
background-color: #E37F68;
  }
  &:disabled {
    @extend .title4_alternateGray;
background-color: #E37F68;
  }
  @extend .title4_alternateGray;
background-color: #E37F68;
@extend .roundedBaseClear;
}
.buttonPrimaryBaseStandard {
  &:active {
    @extend .title4_baseWhite;
background-color: #212121;
  }
  &:disabled {
    @extend .title4_baseWhite;
background-color: #212121;
  }
  @extend .title4_baseWhite;
background-color: #212121;
@extend .roundedBaseBlack;
}
.buttonPrimaryDisabled {
  &:active {
    @extend .body2_alternateGray;
background-color: #E37F68;
  }
  
  @extend .body2_alternateGray;
background-color: #E37F68;
@extend .clearBaseGrayBordered;
}
.buttonSecondaryStandard {
  &:active {
    @extend .body1_accentDark;
  }
  &:disabled {
    @extend .title3_baseGray;
background-color: #EBE6D2;
  }
  @extend .body1_baseGray;
}
.buttonSecondaryWhite {
  &:active {
    @extend .title4_baseBlack;
background-color: #FFFFFF;
  }
  
  @extend .title4_baseBlack;
background-color: #FFFFFF;
}
.buttonSecondaryAccentDark {
  
  
  @extend .body1_accentDark;
}
.buttonSecondaryBorderless {
  
  
  @extend .title4_baseBlack;
@extend .baseGrayBordered;
}
.buttonTertiaryGray {
  &:active {
    @extend .body1_baseWhite;
background-color: #E37F68;
  }
  
  @extend .body1_baseGray;
@extend .alternateGray;
}
.buttonTertiaryAccent {
  &:active {
    @extend .body1_baseWhite;
background-color: #E37F68;
  }
  
  @extend .body1_accentDark;
@extend .roundedAccentClearBackground;
}
.buttonTertiaryBorderless {
  &:active {
    @extend .body1_alternateGray;
background-color: #E37F68;
  }
  
  @extend .body1_alternateGray;
background-color: #E37F68;
@extend .roundedAccentDark;
}
.buttonTertiaryBorderlessClear {
  &:active {
    @extend .body1_baseGray;
  }
  
  @extend .body1_baseGray;
@extend .roundedBaseGray;
}
.buttonTertiaryBorderlessAccent {
  
  
  @extend .body1_accentDark;
}
.buttonTertiaryBorderlessAccentLight {
  
  
  @extend .body4_accentLight;
}
.buttonTertiaryBorderlessBrown {
  
  
  @extend .body4_accentBrown;
}
.buttonTertiaryBorderlessGray {
  
  
  @extend .body1_baseGray;
}
.buttonTertiaryLink {
  
  
  @extend .body1_baseLink;
}
.buttonAccentTertiaryLink {
  
  
  @extend .body1_accentDark;
}
.buttonUnaryClear {
  &:active {
    @extend .body2_accentDark;
  }
  
  @extend .body2_accentDark;
}
.buttonUnaryClearSmall {
  &:active {
    @extend .title4_baseWhite;
background-color: #212121;
  }
  &:disabled {
    @extend .title4_baseWhite;
background-color: #212121;
  }
  @extend .title4_baseWhite;
background-color: #212121;
@extend .roundedBlackClearBackground;
}
.buttonUnaryDark {
  &:active {
    @extend .title4_baseBlack;
background-color: #FFFFFF;
  }
  
  @extend .title4_baseWhite;
background-color: #212121;
@extend .roundedBlackClearBackground;
}
.buttonUnaryAccent {
  &:active {
    @extend .title2_baseGray;
background-color: #E37F68;
  }
  
  @extend .title2_baseWhite;
background-color: #505962;
@extend .circleAccentDark;
}
.buttonUnaryWarning {
  &:active {
    @extend .title4_baseWhite;
background-color: #FF2A00;
  }
  
  @extend .title4_baseWarning;
background-color: #FFFFFF;
@extend .roundedWarningClearBackground;
}
.buttonUnaryGray {
  &:active {
    @extend .title3_accentDark;
background-color: #EBE6D2;
  }
  
  @extend .title3_baseGray;
background-color: #EBE6D2;
@extend .circleGray;
}
.buttonToggleStandard {
  &:active {
    @extend .title2_accentDark;
background-color: #E37F68;
  }
  
  @extend .title2_accentDark;
@extend .circleToggleStandard;
}
.buttonNavStandard {
  &:active {
    @extend .body1_accentDark;
  }
  
  @extend .body1_baseGray;
@extend .alternateGray;
}
.buttonAccentDark {
  &:active {
    @extend .title4_baseBlack;
  }
  
  @extend .title4_baseBlack;
@extend .circleAccentDark;
}.primary {
@extend .body1_baseGray;
 &::placeholder {
    @extend .body2_baseGray;
  }
}
.secondary {
@extend .body1_baseBlack;
 &::placeholder {
    @extend .title4_baseGray;
  }
}