@import "styles/config";
@import "client";

.empty {
  margin: auto;
  height: 55vh;
  .message {
    text-align: center;
    flex-grow: 0;
    max-width: 30rem;
  }

  & img {
    margin-top: 1rem;
    max-width: 15rem;
    @include media-breakpoint-up(md) {
      margin-top: 2rem;
      max-width: 20rem;
    }
  }

  @extend %empty !optional;
}
