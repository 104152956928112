@import "client";

.bar-container {
  background-color: #ccc;
  height: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;

  .bar {
    float: left;
    height: 100%;
    border-radius: 1rem;
    -webkit-animation: loading 10s linear infinite;
    animation: loading 20s linear;
  }

  @extend %bar-container !optional;
}
