@import "styles/config";
@import "client";

.option {
  text-decoration: none;
  padding: 0px 1rem;
  margin: 0px;
  list-style: none;
  &-title {
    padding: 1rem 1rem 0 1rem;
  }

  @extend %option !optional;
}
